import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import styles from './RegistrationForm.module.scss';
import { RegisterFormValues, RegisterDto } from '../../../auth/register/models';
import { Country } from '../../../components/order/models';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { INDIVIDUAL_ACCOUNT_TYPE } from '../../../shared/constants/account-type.constants';
import { toast } from 'react-toastify';
import { getFormSetting } from '../../../containers/cms-container/services';
import { FormSetting } from '../../../containers/cms-container/models';
import { getCountries } from '../../../components/order/services';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import { Link } from 'react-router-dom';

const RegistrationFormComponent = () => {

    const { t, i18n } = useTranslation('translation');
    const [countries, setCountries] = useState<Country[]>([]);
    const [formSetting, setFormSetting] = useState<FormSetting>();
    const [captchaValue, setCaptchaValue] = useState<string>('');
    const navigate = useNavigate();
    const [userWithThisEmailExsits, setUserWithThisEmailExsists] = useState<boolean>(false);

    const fetchCountries = (): void => {
        getCountries()
            .then(response => {
                const countries = response?.data as Country[];
                setCountries(countries || []);
            })
            .catch(error => {
                console.log(error);
            });
    }

    const fetchFormSetting = (): void => {
        getFormSetting().then((response: any) => {
            const formSettings = response?.data as FormSetting;
            setFormSetting(formSettings);
        })
    }

    useEffect(() => {
        fetchCountries();
        fetchFormSetting();
    }, []);

    useEffect(() => {
        if (formSetting) {
            componentDidMount();
        }
    }, [formSetting])

    const displaySuccessToasts = () => {
        let showAdminAcceptance = formSetting?.adminNeedToAcceptUser;
        let showEmailAcceptance = formSetting?.confirmEmail;

        if (showAdminAcceptance && showEmailAcceptance) {
            toast.success(t('register.emailSentAndConfirmationNeed'));
            return;
        }

        if (showAdminAcceptance) {
            toast.success(t('register.registrationConfirmationNeed'));
            return;
        }
        if (showEmailAcceptance) {
            toast.success(t('register.registrationEmailSent'));
            return;
        }

        if (!showAdminAcceptance && !showEmailAcceptance) {
            toast.success(t('register.registeredSuccessfully'));
            return;
        }
    }

    const componentDidMount = () => {
        if (formSetting?.useCaptcha) {
            loadCaptchaEnginge(6);
        }
    };

    const validate = (): boolean => {
        if (!formSetting?.useCaptcha) {
            return true;
        }
        const isCaptchaValid = validateCaptcha(captchaValue);
        if (!isCaptchaValid) {
            setCaptchaValue('');
        }

        return isCaptchaValid;
    }


    const handleCaptchaValueChange = (value: string) => {
        setCaptchaValue(value);
    }

    const prepareRegisterDto = (values: RegisterFormValues): RegisterDto => {
        const savedLanguageCode = localStorage.getItem('lang');
        const registerDto: RegisterDto = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            redirectUrl: process.env.REACT_APP_WEB_SHOP_LOGIN_PAGE as string,
            accountType: INDIVIDUAL_ACCOUNT_TYPE,
            emailHasToBeConfirmed: formSetting?.confirmEmail,
            hasToBeConfirmedByAdmin: formSetting?.adminNeedToAcceptUser,
            language: savedLanguageCode?.toString(),
            address: {
                addressType: "MAIN",
                country: values.country,
                postalCode: values.postalCode,
                city: values.city,
                streetName: values.streetName,
                buildingNumber: values.buildingNumber,
                flatNumber: values.flatNumber,
                region: "",
                addressComments: ""
            },
            contact: {
                contactType: "REPRESENTATIVE",
                title: "",
                phone: values.phone,
                mobile: "",
                website: "",
                contactComments: ""
            }
        }
        return registerDto;
    }

    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ\s]*$/, "Imię musi składać się tylko z liter")
            .required("validation.firstNameRequired"),
        lastName: yup.string()
            .matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ\s]*$/, "Nazwisko musi składać się tylko z liter")
            .required("validation.lastNameRequired"),
        email: yup.string().email("validation.invalidEmail")
            .required("validation.emailRequired"),
        password: yup.string().min(6, "validation.passwordTooShort")
            .required("validation.passwordRequired"),
        passwordConfirmation: yup.string()
            .oneOf([yup.ref('password')], 'validation.passwordConfirmation')
            .required("validation.passwordConfirmationRequired"),
        streetName: yup.string()
            .trim().matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ0-9\s]*$/, 'Nazwa ulicy nie może zawierać znaków specjalnych'),
        buildingNumber: yup.string()
            .matches(/^[a-zA-Z0-9\s]*$/, 'Building number must not contain special characters'),
        flatNumber: yup.string()
            .matches(/^[a-zA-Z0-9\s]*$/, 'Flat number must not contain special characters'),
        postalCode: yup.string(),
        city: yup.string()
            .matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ\s]*$/, 'City must contain only letters'),
        phone: yup.string()
            .matches(/^[^a-zA-Z]*$/, 'Phone number must not contain letters'),
    });

    const register = (values: RegisterFormValues): void => {
        if (validate()) {
            setUserWithThisEmailExsists(false);
            axios.post('auth/register', prepareRegisterDto(values))
                .then((response) => {
                    if (response) {
                        displaySuccessToasts();
                        navigate("/login");
                    } else {
                        showError("Nastąpił błąd podczas rejestracji!")
                    }
                })
                .catch((error) => {
                    setUserWithThisEmailExsists(true);
                });
        }
        else {
            showError(t("register.captchaError"))
        }
    }

    const showError = (message: string) => toast.error(message);

    const findCountryByCode = (code: string): string => {
        const result = countries.find(country => country.code === code)?.id;
        if (!result) return "";
        return result;
    };

    return (

        <Formik enableReinitialize={true} validateOnMount initialValues={{
            firstName: "",
            lastName: "",
            email: "", password: "",
            passwordConfirmation: "",
            addressType: "",
            country: findCountryByCode("PL"),
            postalCode: "",
            city: "",
            streetName: "",
            buildingNumber: "",
            flatNumber: "",
            region: "",
            addressComments: "",
            title: "",
            phone: "",
            mobile: "",
            website: "",
            contactComments: "",
            accountType: ""
        }} validationSchema={validationSchema}
            onSubmit={register}>
            {({ values, handleChange, errors, touched }) =>
                <Form className={styles.form} onMouseEnter={() => { }}>
                    <div className={styles.left_section}>
                        <div className={styles.basic_data}>
                            <div className={styles.section_title}>{t('register.basicData')}</div>

                            <div className={styles.form_row}>
                                <div>
                                    <p className={styles.field_name}>{t('register.firstName')}</p>
                                    <input className={`${styles.normal_field} ${errors.firstName && touched.firstName ? styles.error : ''}`} name="firstName" value={values.firstName} onChange={handleChange}></input>
                                    <div>
                                        {touched.firstName && errors.firstName && (
                                            <div className={styles.errorText}>{t(errors.firstName)}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.ml15}>
                                    <p className={styles.field_name}>{t('register.lastName')}</p>
                                    <input className={`${styles.normal_field} ${errors.lastName && touched.lastName ? styles.error : ''}`} name="lastName" value={values.lastName} onChange={handleChange}></input>
                                    <div>
                                        {touched.lastName && errors.lastName && (
                                            <div className={styles.errorText}>{t(errors.lastName)}</div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div>
                                    <p className={styles.field_name}>{t('register.email')}</p>
                                    <input className={`${styles.normal_field} ${errors.email && touched.email ? styles.error : ''}`} name="email" value={values.email} onChange={handleChange}></input>
                                    <div>
                                        {touched.email && errors.email && (
                                            <div className={styles.errorText}>{t(errors.email)}</div>
                                        )}
                                        {
                                            userWithThisEmailExsits &&
                                            <>
                                                <div className={styles.errorText}>
                                                    {t('myAccountInfo.userDetails.emailAlreadyInUse')}
                                                </div>
                                                <Link className={styles.link} to="/login">
                                                    {t('header.login')}
                                                </Link>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className={styles.ml15}>
                                    <p className={styles.field_name}>{t('register.phone')}</p>
                                    <input className={`${styles.normal_field} ${errors.phone && touched.phone ? styles.error : ''}`} name="phone" value={values.phone} onChange={handleChange}></input>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div>
                                    <p className={styles.field_name}>{t('register.password')}</p>
                                    <input className={`${styles.normal_field} ${errors.password && touched.password ? styles.error : ''}`} name="password" type={"password"} value={values.password} onChange={handleChange}></input>
                                    <div>
                                        {touched.password && errors.password && (
                                            <div className={styles.errorText}>{t(errors.password)}</div>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.ml15}>
                                    <p className={styles.field_name}>{t('register.passwordConfirmation')}</p>
                                    <input className={`${styles.normal_field} ${errors.passwordConfirmation && touched.passwordConfirmation ? styles.error : ''}`} name="passwordConfirmation" type="password" value={values.passwordConfirmation} onChange={handleChange}></input>
                                    <div>
                                        {touched.passwordConfirmation && errors.passwordConfirmation && (
                                            <div className={styles.errorText}>{t(errors.passwordConfirmation)}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={styles.address_data}>
                            <div className={styles.section_title}>{t('register.addressData')}</div>
                            <div className={styles.form_row}>
                                <div className={styles.streetWrapper}>
                                    <p className={styles.field_name}>{t('register.streetName')}</p>
                                    <input className={`${styles.normal_field} ${errors.streetName && touched.streetName ? styles.error : ''}`} name="streetName" value={values.streetName} onChange={handleChange}></input>
                                </div>
                            </div>
                            <div className={styles.form_row}>
                                <div>
                                    <p className={styles.field_name}>{t('register.buildingNumber')}</p>
                                    <input className={`${styles.normal_field} ${errors.buildingNumber && touched.buildingNumber ? styles.error : ''}`} name="buildingNumber" value={values.buildingNumber} onChange={handleChange}></input>
                                </div>
                                <div className={styles.ml15}>
                                    <p className={styles.field_name}>{t('register.flatNumber')}</p>
                                    <input className={`${styles.normal_field} ${errors.flatNumber && touched.flatNumber ? styles.error : ''}`} name="flatNumber" value={values.flatNumber} onChange={handleChange}></input>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div>
                                    <p className={styles.field_name}>{t('register.postalCode')}</p>
                                    <input className={styles.normal_field} name="postalCode" value={values.postalCode} onChange={handleChange}></input>
                                </div>
                                <div className={styles.ml15}>
                                    <p className={styles.field_name}>{t('register.city')}</p>
                                    <input className={`${styles.normal_field} ${errors.city && touched.city ? styles.error : ''}`} name="city" value={values.city} onChange={handleChange}></input>
                                </div>
                            </div>

                            <div className={styles.form_row}>
                                <div className={styles.countriesWrapper}>
                                    <p className={styles.field_name}>{t('register.country')}</p>
                                    <select name="country" className={styles.country_dropdown} value={values.country} onChange={handleChange}>
                                        {countries.map((country: any, index: number) => (
                                            <option key={index} value={country?.id}>{t(`countriesNames.${country?.name}`)}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        {
                            formSetting?.useCaptcha && (
                                <div className={styles.form_row}>
                                    <LoadCanvasTemplate reloadColor="green" />
                                    <input
                                        className={styles.normal_field}
                                        placeholder={t("register.enterCaptcha") || undefined}
                                        value={captchaValue}
                                        onChange={(event) => handleCaptchaValueChange(event.target.value)}
                                    />
                                </div>
                            )
                        }
                        <div className={styles.button_container}>
                            <button type='submit'>{t("register.submit")}</button>
                        </div>
                    </div>
                </Form>
            }
        </Formik>

    );
};

export default RegistrationFormComponent;
