import { ReactNode, useEffect, useState } from 'react';
import styles from './ProducerDetailsView.module.scss';
import { Producer } from '../products-page/components/producers-list/models';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { decodeImageService } from '../../shared/services';
import { Product } from '../products-page/models';
import { getProducerProducts } from './services';
import { ProductPaginated } from '../products-page/components/products-searched-view/models';
import { useTranslation } from 'react-i18next';
import './additional-styles.scss';
import { getProducerAboutMe, getSchemaValueByObjectId } from './services/get-producer-about-me.service';
import OtherDistributorProducts from '../product-details-view/distributor-products/OtherDistributorProducts';
import PhotoGallery from '../../shared/ui-elements/photo-gallery/PhotoGallery';
import { SchemaValueFields } from '../../shared/models/schema-value-fielts.model';
import { AttachmentFile } from '../../shared/models/attachment-file.model';
import { getProducerAttachments } from '../../shared/services/get-attachments.service';
import CartPanel from '../../shared/cart-panel/CartPanel';

export default function ProducerDetailsView(props: any) {
    const [producerAboutMe, setProducerAboutMe] = useState<Producer>();
    const [productsObject, setProductsObject] = useState<ProductPaginated>();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const { t, i18n } = useTranslation('translation');
    const [schemaValues, setSchemaValues] = useState<SchemaValueFields[]>();

    const [userDescription, setUserDescription] = useState<string>('');
    const [imageId, setImageId] = useState<string>('');

    const [producerAttachments, setProducerAttachments] = useState<AttachmentFile[]>();
    const [openCartPanel, setOpenCartPanel] = useState<boolean>(false);
    const { producerId } = useParams();

    const [certificate, setCertificate] = useState<AttachmentFile>();

    useEffect(() => {
        getProducerProductsOnInit();
        getProducerAboutMeContent();
        fetchSchemaValueByObjectId();
        fetchProducerAttachments();
    }, []);

    useEffect(() => {
        if (schemaValues !== null && schemaValues !== undefined) {
            processSchemaValuesData();
        }
    }, [schemaValues])

    useEffect(() => {
        getProducerProductsOnInit();
    }, [currentPage, perPage]);

    const fetchProducerAttachments = () => {
        if (producerId) {
            getProducerAttachments(producerId)
                .then((response) => {
                    if (response && response.data) {
                        const filteredImages = response.data.filter((attachment: AttachmentFile) => attachment.type === 'IMAGE');
                        const certificateAttachment = response.data.find((attachment: AttachmentFile) => attachment.type === 'CERTIFICATE');

                        if (filteredImages.length > 0) {
                            setProducerAttachments(filteredImages as AttachmentFile[]);
                        }

                        if (certificateAttachment) {
                            setCertificate(certificateAttachment as AttachmentFile);
                        }
                    }
                })

        }
    }



    const getProducerAboutMeContent = (): void => {
        if (producerId) {
            getProducerAboutMe(producerId).then((response) => {
                const producerAboutMe = response.data as Producer;
                setProducerAboutMe(producerAboutMe);
            });
        }
    }

    const getProducerProductsOnInit = (): void => {
        if (producerId) {
            getProducerProducts(currentPage + 1, perPage, producerId).then((response) => {
                const paginatedList = response?.data as ProductPaginated;
                setProductsObject(paginatedList);
            })
        }
    }

    const fetchSchemaValueByObjectId = (): void => {
        if (producerId !== null && producerId !== undefined) {
            getSchemaValueByObjectId(producerId).then((response) => {
                if (response !== undefined && response.data !== undefined && response.data.values !== undefined) {
                    setSchemaValues(response.data.values as SchemaValueFields[]);
                }
            })
        }
    }

    const processSchemaValuesData = () => {
        if (schemaValues !== null && schemaValues !== undefined) {
            const userDesc = schemaValues.find((x) => x.textValue !== null)?.textValue;
            if (userDesc !== null && userDesc !== undefined) {
                setUserDescription(userDesc);
            }
        }
    }

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
        handleToggleCartPanel(true);
    }

    const handleToggleCartPanel = (value: boolean) => {
        setOpenCartPanel(value);
    }


    return (
        <div className={styles.main_container}>
            <div className={styles.producer_container}>
                <div className={styles.producer_picture}>
                    {producerAttachments !== undefined && producerAttachments.length > 0 && (
                        <PhotoGallery photos={producerAttachments} />
                    )}
                </div>
                <div className={styles.producer_description}>
                    <div className={styles.text_container}>
                        <p className={styles.name}>
                            {producerAboutMe?.name.toUpperCase()}
                        </p>
                        {parse(userDescription !== null ? userDescription : "")}
                    </div>
                </div>
            </div>
            {certificate && (
                <div className={styles.section_wrapper}>
                    <div className={styles.certificate_item}>
                        <p className={styles.name}>
                            {t("producerDetails.cards.producerCertificates")}
                        </p>
                        <div className={styles.certificate_details}>
                            <img src={decodeImageService(certificate?.content, certificate.name)} />
                            <div className={styles.certificate_description}>
                                <div className={styles.certificate_content}>
                                    {parse(producerAboutMe?.certificateDto?.certificate?.description ? producerAboutMe?.certificateDto?.certificate?.description : "")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {productsObject && (
                <div className={styles.other_products}>
                    <div className={styles.cards_container}>
                        <OtherDistributorProducts productsObject={productsObject} onCartItemsChange={handleCartItemsChange} />
                    </div>
                </div>)
            }
            {openCartPanel &&
                <CartPanel toggleOpenCartPanel={handleToggleCartPanel} />
            }
        </div>

    )
}
