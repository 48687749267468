import axios from "axios";
import { Product } from "../../../models";
import { Filters } from "../../../../../models/filters.model";

export const getNewestProducts = (filters: Filters) => {
    return axios.get<Product[]>("products/NewestProducts?" + "pickupPointId=" + filters.pickupPointId + "&productName=" + filters.productName + "&categoryId=" + filters.categoryId + "&sortBy=" + filters.sortBy);
}

export const getMostPurchasedProducts = (filters: Filters) => {
    return axios.get<Product[]>("products/MostPurchasedProducts?" + "pickupPointId=" + filters.pickupPointId + "&productName=" + filters.productName + "&categoryId=" + filters.categoryId + "&sortBy=" + filters.sortBy);
}
