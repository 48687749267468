//FILE EMPTY GUID
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

//PRODUCT CARD
export const PRODUCT_CARD_WIDTH = 240;
export const PRODUCT_CARD_HEIGHT = 160;

//PRODUCER CARD 
export const PRODUCER_CARD_WIDTH = 160;
export const PRODUCER_CARD_HEIGHT = 160;

//SMALL PRODUCER CARD (CRICLE)
export const SMALL_PRODUCER_CARD_WIDTH = 80;
export const SMALL_PRODUCER_CARD_HEIGHT = 80;

