import React, { useEffect, useState } from "react";
import styles from "./MobileSidePanel.module.scss";
import ProductsCategories from "../../../components/products-page/components/products-categories/ProductsCategories";
import { t } from "i18next";
import { CmsTitlesAndUrls } from "../../../containers/cms-container/models/cms-titles-url-by-code";
import { Link } from "react-router-dom";
import Search from "../../../components/products-page/components/search/Search";

interface MobileSidePanelProps {
    loading: boolean;
    titlesAndUrls: CmsTitlesAndUrls;
    closePanel: any;
    selectedCategory?: string;
    isSearched: boolean;
    handleCategorySelect: any;
    updateIsSearched: any;
}

export default function MobileSidePanel({ loading, titlesAndUrls, closePanel, selectedCategory, isSearched, handleCategorySelect, updateIsSearched }: MobileSidePanelProps) {
    const [activeTab, setActiveTab] = useState("products");

    const handleSearchedClicked = () => {
        updateIsSearched(true);
    };

    return (
        <div className={styles.container}>
            <div className={styles.left_side}>
                <Search
                    searchedClicked={handleSearchedClicked}
                    onSelectCategory={handleCategorySelect}
                    categoryId={selectedCategory} />
                <div className={styles.tabs}>
                    <button
                        className={`${styles.tab} ${activeTab === "products" ? styles.active : ""
                            }`}
                        onClick={() => setActiveTab("products")}
                    >
                        Produkty
                    </button>
                    <button
                        className={`${styles.tab} ${activeTab === "menu" ? styles.active : ""
                            }`}
                        onClick={() => setActiveTab("menu")}
                    >
                        Menu
                    </button>
                </div>

                {activeTab === "menu" && (
                    <nav className={styles.menu}>
                        <ul>
                            <li><Link to="/">{t("header.shop")}</Link></li>
                            <li><Link to="/producers">{t("header.producers")}</Link></li>
                            {!loading && titlesAndUrls.HOW_TO_BUY?.url && titlesAndUrls?.HOW_TO_BUY?.title && (
                                <li><Link to={titlesAndUrls.HOW_TO_BUY.url}>{titlesAndUrls.HOW_TO_BUY.title}</Link></li>
                            )}
                            {!loading && titlesAndUrls.CONTACT?.url && titlesAndUrls.CONTACT.title && (
                                <li><Link to={titlesAndUrls.CONTACT.url}>{titlesAndUrls.CONTACT.title}</Link></li>
                            )}
                            {!loading && titlesAndUrls.JOIN_US?.url && titlesAndUrls.JOIN_US?.title && (
                                <li><Link to={titlesAndUrls.JOIN_US.url}><p>{titlesAndUrls.JOIN_US.title}</p></Link></li>
                            )}
                            {!loading && titlesAndUrls.ABOUT?.url && titlesAndUrls.ABOUT?.title && (
                                <li><Link to={titlesAndUrls.ABOUT.url}><p>{titlesAndUrls.ABOUT.title}</p></Link></li>
                            )}
                        </ul>
                    </nav>
                )}
                {activeTab === "products" && (
                    <ProductsCategories
                        isSearched={isSearched}
                        onSelectCategory={handleCategorySelect}
                        categoryId={selectedCategory}
                        displayPickupPoint={true}
                    />

                )}
            </div>

            <div className={styles.right_side} onClick={() => closePanel()} />
        </div >
    );
}
