import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AvaliableIn.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { PickupPoint } from '../../../shared/map/models';
import SelectList from '../../../shared/select-list/SelectList';

export default function AvaliableIn(props: { productId: string }) {

    const { t, i18n } = useTranslation('translation');
    const productsIdsWithPickupPoints = useSelector((state: RootState) => state.productsIdsWithPickupPoints);
    const [pickupPoints, setPickupPoints] = useState<PickupPoint[] | undefined>([]);

    useEffect(() => {
        setPickupPoints(productsIdsWithPickupPoints?.find((obj: any) => obj.productId === props.productId)?.pickupPoints);
    }, [productsIdsWithPickupPoints]);
    return (
        <div className={styles.avaliableInContainer}>
            <p>{t("pickupPointsFilters.avaliableIn")}</p>
            <SelectList displayDefaultValue={false} pickupPoints={pickupPoints} disabled={false}></SelectList>
        </div>
    );
}
