import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientPanelMenu from './components/client-panel-menu/ClientPanelMenu';
import styles from './ClientPanel.module.scss';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

export default function ClientPanel() {
    const naviagte = useNavigate();
    const { t, i18n } = useTranslation('translation');

    useEffect(() => {

    }, []);

    const handleSectionChange = (route: any) => {
        naviagte(route)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.main_container}>
                <div className={styles.left_section}>
                    <ClientPanelMenu onSectionChange={handleSectionChange}></ClientPanelMenu>
                </div>
                <div className={styles.right_section}>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}
