import axios from 'axios'
import { OrderModel } from '../components/order/models';

export const getProductById = (id: string): any => {
    return axios.get<string>(`products/product/${id}`)
}

export const getProductImage = (productId: string): any => {
    return axios.get("Images/products/" + productId);
}

export const getNotAvailableProducts = (order: OrderModel) => {
    return axios.post("Products/notAvailableProducts", order);
}
