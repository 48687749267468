export const toggleFavourites = (productId: string): void => {
    if(!(localStorage.getItem("favourites") as string)) {
        localStorage.setItem("favourites", JSON.stringify([]));
    }
    const arrayOfFavourites = JSON.parse(localStorage.getItem("favourites")as string);
    if(Array.isArray(arrayOfFavourites)) {
        !(arrayOfFavourites.includes(productId)) ? arrayOfFavourites.push(productId) : arrayOfFavourites.splice(arrayOfFavourites.indexOf(productId), 1);
        localStorage.setItem("favourites", JSON.stringify(arrayOfFavourites));
    }
}

export const getFavouritesStatus = (productId: string): boolean => {
    if(localStorage.getItem("favourites") as string) {
        const array = JSON.parse(localStorage.getItem("favourites") as string);
        if(Array.isArray(array)) {
            return array.includes(productId);
        }
    }
    return false;
} 