import { useNavigate } from 'react-router-dom';
import LoginFormComponent from '../../plugins/components/LoginForm/LoginForm';
import styles from './ContinueOrCreateAccount.module.scss';
import { useEffect } from 'react';

export default function ContinueOrCreateAccount() {
    const navigate = useNavigate();
    const buttonAction = (path: string) => {
        navigate(path);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.left_side}>
                <div className={styles.form_container}>
                    <h3>LOGIN</h3>
                    <LoginFormComponent redirectToOrder={true}></LoginFormComponent>
                </div>
            </div>
            <div className={styles.right_side}>
                <div className={styles.actions_container}>
                    <div className={styles.header}>NIE MASZ KONTA? ZAREJESTRUJ SIĘ!</div>
                    <div className={styles.buttons_container}>
                        <button
                            className={`${styles.actionButton} ${styles.firstButton}`}
                            onClick={() => {
                                buttonAction('/order');
                            }}
                        >
                            KONTYNUUJ JAKO GOŚĆ
                        </button>
                        <button
                            className={`${styles.actionButton} ${styles.secondButton}`}
                            onClick={() => {
                                buttonAction('/register');
                            }}
                        >
                            ZAREJESTRUJ SIĘ
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
