import { t } from 'i18next';
import styles from './ContactSection.module.scss';
import { useEffect, useState } from 'react';
import { Form, Formik, useFormik } from 'formik';
import * as yup from "yup";
import { Contact } from '../models/contact.model';
import { UserData } from '../models';
import { updateUserDetails } from '../services';

export interface ContactSectionProps {
    userDetails: UserData
    fetchUserDetails: () => void;
}

export default function ContactSection({ userDetails, fetchUserDetails }: ContactSectionProps) {

    const [showEditForm, setShowEditForm] = useState<boolean>(false);

    const titles = ["mr", "mrs", "miss", "ms", "other"];

    const handleToggleEditForm = (value: boolean) => {
        setShowEditForm(value);
    }

    const [contactInitialValues, setContactInitialValues] = useState({
        title: 'mr',
        firstName: '',
        lastName: '',
        phone: '',
        mobile: '',
        email: '',
    });

    useEffect(() => {
        const contact = userDetails.contact;
        if (contact) {
            setContactInitialValues({
                title: contact.title || 'mr',
                firstName: contact.firstName || '',
                lastName: contact.lastName || '',
                phone: contact.phone || '',
                mobile: contact.mobile || '',
                email: contact.email || '',
            });
        }
    }, [userDetails]);

    const validationSchema = yup.object({
        title: yup.string(),
        firstName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        lastName: yup
            .string()
            .matches(/^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s]+$/, 'Must be only letters')
            .required(),
        phone: yup
            .string()
            .matches(/^\+?[0-9]+$/),
        mobile: yup
            .string()
            .matches(/^\+?[0-9]+$/),
        email: yup
            .string()
            .email()
            .required()
    })


    const handleSubmit = async (values: any, isValid: boolean): Promise<void> => {
        if (isValid) {
            userDetails.contact = values as Contact;
            setShowEditForm(false);
            updateUserDetails(values as Contact).then(() => {
                fetchUserDetails()
            })
        }
    }

    return (
        <div className={styles.section}>
            <p className={styles.section_subtitle}>{t("myAccountInfo.contact.title")}</p>
            {!showEditForm ?
                <div className={styles.section_data}>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact._title")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.title ? t("myAccountInfo.contact." + userDetails?.contact?.title?.toLowerCase()) : ""}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact.firstName")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.firstName}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact.lastName")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.lastName}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact.phone")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.phone}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact.mobile")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.mobile}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.contact.email")}:</p>
                        <p className={styles.data_value}>{userDetails?.contact?.email}</p>
                    </div>
                    <div className={styles.edit_button}>
                        <button onClick={() => { handleToggleEditForm(true) }}>EDYTUJ</button>
                    </div>
                </div>
                :
                <Formik
                    enableReinitialize={true}
                    initialValues={contactInitialValues}
                    onSubmit={() => { }}
                    validationSchema={validationSchema}>

                    {({ values, touched, errors, handleChange, isValid }) =>
                        <Form className={styles.form}>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact._title")}:</p>
                                <select
                                    onChange={handleChange}
                                    className={styles.normal_field}
                                    value={values.title}
                                    name="title">
                                    {titles.map((title) => (
                                        <option key={title} value={title}>
                                            {t(`myAccountInfo.contact.${title}`)}
                                        </option>
                                    ))}
                                </select>

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact.firstName")}:</p>
                                <input
                                    onChange={handleChange}
                                    name="firstName"
                                    value={values.firstName}
                                    className={`${styles.normal_field} ${errors.firstName && touched.firstName ? styles.error : ''}`}
                                />

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact.lastName")}:</p>
                                <input
                                    onChange={handleChange}
                                    name="lastName"
                                    value={values.lastName}
                                    className={`${styles.normal_field} ${errors.lastName && touched.lastName ? styles.error : ''}`}
                                />

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact.phone")}:</p>
                                <input
                                    onChange={handleChange}
                                    name="phone"
                                    value={values.phone}
                                    className={`${styles.normal_field} ${errors.phone && touched.phone ? styles.error : ''}`}
                                />

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact.mobile")}:</p>
                                <input
                                    onChange={handleChange}
                                    name="mobile"
                                    value={values.mobile}
                                    className={`${styles.normal_field} ${errors.mobile && touched.mobile ? styles.error : ''}`}
                                />
                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.field_name}>{t("myAccountInfo.contact.email")}:</p>
                                <input
                                    onChange={handleChange}
                                    name="email"
                                    value={values.email}
                                    className={`${styles.normal_field} ${errors.email && touched.email ? styles.error : ''}`}
                                />
                            </div>
                            <div className={styles.buttons}>
                                <button onClick={() => handleSubmit(values, isValid)} className={styles.save_button}>{t('myAccountInfo.userDetails.save')}</button>
                                <button onClick={() => { handleToggleEditForm(false) }} className={styles.save_button}>{t('myAccountInfo.userDetails.cancel')}</button>
                            </div>
                        </Form>
                    }
                </Formik>
            }
        </div >
    )
}