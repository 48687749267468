import { useEffect, useState } from 'react';
import styles from './CategoryImage.module.scss';
import { getProductImagesService } from '../../../../../shared/services';

interface CategoryImageProps {
    categoryId: string;
}

export default function CategoryImage({ categoryId }: CategoryImageProps) {
    const [imageContent, setImageContent] = useState<string | null>(null);
    const [isSvg, setIsSvg] = useState<boolean>(false);

    useEffect(() => {
        if (categoryId && categoryId !== 'all') {
            getProductImagesService(categoryId).then((response) => {
                if (response.data && response.data.length > 0) {
                    const image = response.data[0];
                    const { content, name } = image;
                    if (content && name) {
                        const isSvgFile = name.toLowerCase().endsWith('.svg');
                        setIsSvg(isSvgFile);
                        setImageContent(isSvgFile ? atob(content) : `data:image/${getImageType(name)};base64,${content}`);
                    }
                }
            });
        }
    }, [categoryId]);

    const getImageType = (fileName: string): string => {
        return fileName.toLowerCase().endsWith('.png') ? 'png' : 'jpeg';
    };

    return (
        <div className={styles.categoryImage}>
            {imageContent && (
                isSvg ? (
                    <div
                        className={styles.svgContainer}
                        dangerouslySetInnerHTML={{ __html: imageContent }}
                    />
                ) : (
                    <img className={styles.image} src={imageContent} alt="Category" />
                )
            )}
        </div>
    );
}
