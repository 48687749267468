import React, { useEffect, useRef, useState } from 'react';
import styles from './ProductDetailsView.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Product } from '../products-page/models';
import * as productDetailsService from './services';
import { Category } from '../products-page/components/products-categories/models/Category.model';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ProductImage } from '../../shared/product-card/models/product-image.model';
import { calculateBrutto, decodeImageService, getFavouritesStatus, getImagesByIds, getProductImagesService } from '../../shared/services';
import { addToCart } from '../cart/services/cart.service';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './carousel/carousel.scss';
import ProductInfoDetails from '../product-info-details/ProductInfoDetails';
import OtherDistributorProducts from './distributor-products/OtherDistributorProducts';
import uuid from 'react-uuid';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { usePluginStore } from 'react-pluggable';
import parse from 'html-react-parser';
import { Filters } from '../../models/filters.model';
import CartPanel from '../../shared/cart-panel/CartPanel';
import { getProductsForCategoryService } from './services';
import { EMPTY_GUID } from '../../shared/constants/images-resolution.constants';
import { getProducerProducts } from '../producer-details-view/services';

const cartIcon = require("../../assets/icons/cart.svg").default;
const productImagePlaceholder = require("../../assets/product_image_placeholder.png");

export default function ProductDetailsView(props: any) {

    const filters = useSelector((state: RootState) => state.filters);
    const currency = useSelector((state: RootState) => state.currency);

    const { t, i18n } = useTranslation('translation');
    const location = useLocation();
    const navigate = useNavigate();
    const { productId, categoryId, producerId } = useParams();

    const [products, setProducts] = useState<Product[]>([]);
    const [categoryTree, setCategoryTree] = useState<Category[]>([]);

    const [activeProduct, setActiveProduct] = useState<Product>();
    const [activeProductDescription, setActiveProductDescription] = useState<string>();

    const [currentAllImages, setCurrentAllImages] = useState<ProductImage[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const carouselRef = useRef<any>(null);

    const [quantity, setQuantity] = useState<number>(1);
    const [favouritesStatus, setFavouritesStatus] = useState<boolean>(false);

    const [openCartPanel, setOpenCartPanel] = useState<boolean>(false);

    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    const getProductsForCategory = async (): Promise<void> => {
        let fetchedProducts: Product[] = [];
        const categoryIdFromLocalStorage = localStorage.getItem('categoryId');
        if (categoryIdFromLocalStorage && !producerId) {
            const newFilters: Filters = {
                pickupPointId: null,
                categoryId: categoryIdFromLocalStorage === 'all' ? EMPTY_GUID : categoryIdFromLocalStorage,
                productName: null
            };

            const response = await getProductsForCategoryService(categoryIdFromLocalStorage, newFilters);
            if (response) {
                fetchedProducts = response.data as Product[];
            }
        } else if (producerId) {
            const response = await getProducerProducts(0, 20, producerId);
            if (response) {
                fetchedProducts = response.data.items as Product[];
            }
        }

        if (fetchedProducts.length > 0 && productId) {
            const activeProduct = fetchedProducts.find(x => x.id === productId);
            setActiveProduct(activeProduct);

            if (producerId && producerId !== EMPTY_GUID) {
                fetchedProducts = fetchedProducts.filter(x => x.contractorId === producerId);
            }
        }

        setProducts(fetchedProducts);
    };


    const fillCategoryTree = (categories: Category[], parent: Category, tree: Category[]) => {
        if (parent) {
            const addToTree = categories?.find(x => x.id === parent?.parentProductId) as Category;
            if (addToTree) {
                tree.unshift(addToTree);
            }
            fillCategoryTree(categories, addToTree, tree);
        }
        else {
            setCategoryTree(tree);
        }
    };

    const getImagesForActiveProduct = (): void => {
        if (activeProduct && activeProduct.imageIds && activeProduct.imageIds?.length > 0) {
            getImagesByIds(activeProduct.imageIds).then((response) => {
                if (response.data) {
                    const images = response.data as ProductImage[];
                    setCurrentAllImages(images);
                }
            })
        }
    };
    const getDescriptionForActiveProduct = async (): Promise<void> => {
        const response = await productDetailsService.getDescriptionForProduct(productId as string);
        setActiveProductDescription(response?.data as string);
    };

    const getFavouritesStatusForProduct = (): void => {
        setFavouritesStatus(getFavouritesStatus(productId as string));
    }


    useEffect(() => {
        window.scrollTo({ top: 0 });
        getProductsForCategory();
    }, [filters]);


    useEffect(() => {
        getImagesForActiveProduct();
        getDescriptionForActiveProduct();
        getFavouritesStatusForProduct();
    }, [activeProduct]);

    const handleNextProduct = (): void => {
        if (products) {
            const indexOfActiveProduct = products.indexOf(activeProduct as Product);
            if (indexOfActiveProduct < products.length - 1) {
                const nextProduct = products[indexOfActiveProduct + 1];
                handleActiveProductChange(nextProduct);
                const url = `../product-details/${nextProduct.id}/${categoryId}`;
                const fullUrl = producerId ? `${url}/${producerId}` : url;
                navigate(fullUrl, { replace: true });
                setCurrentIndex(0);
            }
        }
    };

    const handlePreviousProduct = (): void => {
        if (products) {
            const indexOfActiveProduct = products.indexOf(activeProduct as Product);
            if (indexOfActiveProduct > 0) {
                const previousProduct = products[indexOfActiveProduct - 1];
                handleActiveProductChange(previousProduct);
                const url = `../product-details/${previousProduct.id}/${categoryId}`;
                const fullUrl = producerId ? `${url}/${producerId}` : url;
                navigate(fullUrl, { replace: true });
                setCurrentIndex(0);
            }
        }
    };


    const handleActiveProductChange = (product: Product) => {
        setActiveProduct(product);
    };

    const getIndexOfActiveProduct = (): number => {
        return products?.indexOf(activeProduct as Product);
    }

    const handleIncreaseQuantity = (value: number): void => {
        setQuantity(prev => {
            if (prev < (activeProduct?.numberOfItems as number)) {
                return prev + value;
            } else {
                return prev;
            }
        });
    };


    const handleDecreaseQuantity = (value: number): void => {
        const finalValue = quantity - value;
        if (finalValue < 0) {
            setQuantity(0);
        }
        else if (quantity > 0) {
            setQuantity(prev => prev - value);
        }
    };

    const placeItemInCart = (product: Product, quantity: number, image: ProductImage): void => {
        addToCart(product, quantity, image);
        localStorage.setItem("recentlyAddedProductId", product.id);
        props.onCartItemsChange();
        handleToggleCartPanel(true);
    }

    const handleCartItemsChange = () => {
        props.onCartItemsChange();
        handleToggleCartPanel(true);
    }

    const handleToggleCartPanel = (value: boolean) => {
        setOpenCartPanel(value);
    }

    const handleDoubleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(",", ".");
        if (inputValue.includes(".")) {
            inputValue += "0";
        }
        if (!isNaN(parseFloat(inputValue)) && activeProduct?.numberOfItems !== undefined) {
            let parsedValue = parseFloat(inputValue);
            parsedValue = parseFloat(parsedValue.toFixed(2));
            if (parsedValue > activeProduct?.numberOfItems) {
                setQuantity(activeProduct?.numberOfItems as number);
            } else if (parsedValue < 0) {
                setQuantity(0);
            } else {
                setQuantity(parsedValue);
            }
        }
    };

    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(/[^0-9]/g, '');

        if (inputValue !== '' && !isNaN(Number(inputValue)) && activeProduct !== undefined) {
            if (Number(inputValue) > activeProduct?.numberOfItems) {
                setQuantity(activeProduct?.numberOfItems);
            }
            else {
                setQuantity(Number(inputValue));
            }
        } else {
            setQuantity(0);
        }
    };

    const handleContractorRedirect = () => {
        navigate("/producer-details-view/" + activeProduct?.contractorId);
    }


    return (
        <div className={styles.main_container}>
            <div className={styles.upper_green_area}>
                <div className={styles.categories}>
                    <p onClick={() => navigate("/")}>Home</p>
                    <p>/</p>
                    <p onClick={() => navigate("/")}>{t("productDetails.shop")} </p>
                    {
                        categoryTree && categoryTree?.map((cat, index) => (
                            <div key={uuid()} className={styles.category_list}>
                                <p>/</p>
                                <p onClick={() => { navigate("../catalogue/" + cat?.id, { replace: true }); window.scrollTo({ top: 750 }); }} className={styles.category_name}>{cat?.name}</p>
                            </div>
                        ))
                    }
                    <p>/</p>
                    <p className={styles.product_name}>{activeProduct?.name}</p>
                </div>
            </div>
            <div className={styles.product_section}>
                <div className={styles.images_section}>

                    <Carousel
                        selectedItem={currentIndex}
                        onChange={setCurrentIndex}
                        className={styles.carousel}
                        ref={carouselRef}
                        showArrows={false}
                        showStatus={false}
                        showThumbs={currentAllImages?.length > 0}
                        showIndicators={currentAllImages?.length > 0}
                        thumbWidth={window.innerWidth > 1270 ? 100 : 50}
                    >
                        {
                            currentAllImages?.length > 0 ? currentAllImages?.map((img, index) => (
                                <div key={uuid()} className={styles.image}>
                                    <div className={styles.arrow_back_container} onClick={() => setCurrentIndex(old => Math.max(old - 1, 0))}>
                                        <ArrowBackIosIcon className={styles.arrow_back} fontSize='small' />
                                    </div>
                                    <img src={decodeImageService(img?.content as string, img?.name as string)} alt={img?.name as string} />
                                    <div className={styles.arrow_forward_container} onClick={() => setCurrentIndex(old => Math.min(old + 1, currentAllImages?.length - 1))}>
                                        <ArrowForwardIosIcon className={styles.arrow_forward} fontSize='small' />
                                    </div>
                                </div>
                            )
                            ) : [
                                <div key={uuid()} className={styles.image}>
                                    <div className={styles.arrow_back_container}>
                                        <ArrowBackIosIcon className={styles.arrow_back} fontSize='small' />
                                    </div>
                                    <img src={productImagePlaceholder} alt="Image" />
                                    <div className={styles.arrow_forward_container}>
                                        <ArrowForwardIosIcon className={styles.arrow_forward} fontSize='small' />
                                    </div>
                                </div>
                            ]
                        }
                    </Carousel>
                </div>
                <div className={styles.data_section}>
                    <div className={styles.prev_next_section}>
                        <div className={getIndexOfActiveProduct() === 0 ? `${styles.previous} ${styles.inactive}` : styles.previous}
                            onClick={() => handlePreviousProduct()}>
                            <ArrowBackIosIcon fontSize='small' className={styles.arrow_back} />
                            <p>{t("productDetails.previous")}</p>
                        </div>
                        <div className={getIndexOfActiveProduct() === products.length - 1 ? `${styles.next} ${styles.inactive}` : styles.next}
                            onClick={() => handleNextProduct()}>
                            <p>{t("productDetails.next")}</p>
                            <ArrowForwardIosIcon fontSize='small' className={styles.arrow_forward} />
                        </div>
                    </div>
                    <div className={styles.product_name}>
                        <p>{activeProduct?.name}</p>
                    </div>
                    <div className={styles.price}>
                        <div>{activeProduct?.price ? calculateBrutto(activeProduct?.price, activeProduct?.vat) + " " + currency : " 0.00 " + currency}
                            <span> / {t("cena za ")} {activeProduct?.saleUnitName}</span>
                        </div>
                    </div>
                    <div className={styles.product_description}>
                        {parse(activeProductDescription ? activeProductDescription : (""))}
                    </div>
                    <Renderer placement="page:ingredients" />
                    <div className={styles.available}>
                        <p>{t("productDetails.producer")}: <span onClick={handleContractorRedirect} className={styles.green_link}>{activeProduct?.producerName}</span></p>
                    </div>
                    <div className={styles.available}>
                        <p>{t("productDetails.available")}: <span> {activeProduct && activeProduct?.numberOfItems % 1 === 0 ?
                            activeProduct?.numberOfItems :
                            activeProduct?.numberOfItems.toFixed(2)
                        } {activeProduct?.saleUnitName}</span></p>
                    </div>
                    <div className={styles.availableIn}>
                        <p>{t("productDetails.availableInPickupPoints")}:</p>
                        <div className={styles.container}>
                            <Renderer placement="page:product-details" />
                        </div>
                    </div>
                    <div className={styles.counter_and_button_container}>
                        <div className={styles.counter}>
                            <button
                                onClick={() => handleDecreaseQuantity(activeProduct?.isQuantityNaturalNumber ? 1 : 0.5)}
                                className={((activeProduct?.numberOfItems as number > 0) && (quantity !== 0)) ? styles.minus : `${styles.minus}`}
                            >
                                <div>-</div>
                            </button>
                            <input
                                type={activeProduct?.isQuantityNaturalNumber ? 'text' : 'number'}
                                className={styles.amount}
                                value={String(quantity)}
                                onChange={activeProduct?.isQuantityNaturalNumber ? handleIntInputChange : handleDoubleInputChange}
                                min={0}
                                step="any"
                            />
                            <div>{activeProduct?.saleUnitName}</div>
                            <button
                                onClick={() => handleIncreaseQuantity(activeProduct?.isQuantityNaturalNumber ? 1 : 0.5)}
                                className={((activeProduct?.numberOfItems as number > 0) && (quantity !== activeProduct?.numberOfItems as number)) ? styles.plus : `${styles.plus}`}
                                disabled={quantity >= (activeProduct?.numberOfItems as number)}
                            >
                                <div>+</div>
                            </button>
                        </div>
                        <button className={activeProduct?.numberOfItems as number > 0 && quantity > 0 && activeProduct?.minOrderQuantity !== undefined && quantity >= activeProduct.minOrderQuantity ? styles.buy_button : `${styles.buy_button} ${styles.inactive}`}
                            onClick={() => { placeItemInCart(activeProduct as Product, quantity ? quantity : 1, currentAllImages[0]); }}
                            disabled={activeProduct?.numberOfItems as number <= 0 || quantity <= 0 || (activeProduct?.minOrderQuantity !== undefined && quantity < activeProduct.minOrderQuantity)}
                        >
                            <img src={cartIcon} alt="Cart" />
                            <p>{t("productDetails.addToBasket")}</p>
                        </button>
                    </div>
                </div>
            </div>
            <OtherDistributorProducts
                productId={productId}
                onCartItemsChange={handleCartItemsChange}
            />
            {openCartPanel &&
                <CartPanel toggleOpenCartPanel={handleToggleCartPanel}></CartPanel>
            }
        </div >
    )
}
