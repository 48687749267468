import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styles from './Paginator.module.scss'
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { number } from 'yup';

export default function Paginator(props: any) {
    const { perPage, onPageClick, onPerPageChange, dataLength, currentPage, totalPages } = props;
    const { t, i18n } = useTranslation('translation');

    const handlePageClick = (selectedPage: any) => {
        onPageClick(selectedPage);
    };

    const handlePerPageChange = (event: any) => {
        onPerPageChange(event);
    };

    const pageCount = Math.ceil(dataLength / perPage) as number;

    return (
        <>
            {totalPages > 0 &&
                <div className={styles.paginator_container}>
                    <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    pageCount={totalPages ? totalPages : 0}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={styles.pagination}
                    activeClassName={styles.active}
                    forcePage={currentPage ? currentPage : 0}
                    />
                </div>
            }
        </> 
    );
}
