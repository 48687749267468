import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ClientPanelMenu.module.scss'
import { useLocation, useNavigate } from 'react-router-dom';

export default function ClientPanelMenu(props: any) {

    const location = useLocation();
    const { t, i18n } = useTranslation('translation');
    const [selectedSection, setSelectedSection] = useState<string>("");
    const [hoverElement, setHoverElement] = useState<string | undefined>(undefined);

    const menuItems = [
        {
            name: "clientPanel.menu.myAccount",
            code: "",
            redirectUrl: ""
        },
        {
            name: "clientPanel.menu.userDetails",
            code: "user-details",
            redirectUrl: "user-details"
        },
        {
            name: "clientPanel.menu.addressesBook",
            code: "address",
            redirectUrl: "address"
        },
        {
            name: "clientPanel.menu.myOrders",
            code: "orders",
            redirectUrl: "orders"
        }
    ];

    useEffect(() => {
        let section = location.pathname.split('/')[2];
        section = section ? section : "";
        setSelectedSection(section);
    }, []);

    const handleSelectedSectionChange = (section: any): void => {
        if (selectedSection === section.code) {
            return;
        }
        setSelectedSection(section.code);
        props.onSectionChange(section.redirectUrl)
    };

    return (
        <>
            <div className={styles.categories_container}>
                <ul className={styles.category_list}>
                    {menuItems.map((section, index) => (
                        <li key={index} className={styles.category_item} onClick={() => handleSelectedSectionChange(section)} onMouseOver={() => { setHoverElement(section.code) }} onMouseLeave={() => { setHoverElement(undefined) }}>
                            {selectedSection !== section.code ? <>
                                {
                                    section.code === hoverElement &&
                                    <span className={styles.line}></span>
                                }
                                < div className={hoverElement === section.code ? `${styles.content} ${styles.green}` : styles.content}>
                                    <div className={styles.category_item_content}>
                                        <div className={styles.category_item_image}>

                                        </div>
                                        <p >{t(section.name)}</p>
                                    </div>
                                </div> </> :
                                <>
                                    <span className={styles.line}></span>
                                    <div className={styles.content_selected}>
                                        <div className={styles.category_item_content}>
                                            <div className={styles.category_item_image}>

                                            </div>
                                            <p>{t(section.name)}</p>
                                        </div>
                                    </div>
                                </>
                            }
                        </li>
                    ))}
                </ul>
            </div >
        </>
    )
}
