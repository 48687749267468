import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './OrderDetails.module.scss';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeliveryMethod, OrderItem, OrderModel, PaymentMethod } from '../../../order/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { decodeImageService, getProductImagesService } from '../../../../shared/services';
import { getDeliveryById, getOrderById, getPaymentById, getUserDetails } from '../my-account-info/services';
import { ProductImage } from '../../../../shared/product-card/models/product-image.model';
import { OrderItemImage } from '../../../order-confirmation/models';
import { replace } from 'formik';
import dayjs from 'dayjs';
import { UserData } from '../my-account-info/models';

export default function OrderDetails() {

    const currency = useSelector((state: RootState) => state.currency);
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translation');
    const [order, setOrder] = useState<OrderModel>();
    const [productImages, setProductImages] = useState<OrderItemImage[]>([]);
    const [delivery, setDelivery] = useState<DeliveryMethod | undefined>(undefined);
    const [payment, setPayment] = useState<PaymentMethod | undefined>(undefined);
    const [userDetails, setUserDetails] = useState<UserData | undefined>(undefined);
    const { orderId } = useParams();
    useEffect(() => {
        getOrder();
    }, []);

    useEffect(() => {
        getProductImages();
    }, [order])

    const getOrder = (): any => {
        if (orderId) {
            getOrderById(orderId).then((response) => {
                setOrder(response.data as OrderModel);
            })
        }
    }

    const getProductImages = async (): Promise<void> => {
        const items = order?.items as OrderItem[];
        const newProductImages = [];
        if (items) {
            for (const item of items) {
                const response = await getProductImagesService(item?.productId);
                if (response) {
                    const images = response?.data as ProductImage[];

                    for (const image of images) {
                        newProductImages.push({
                            productId: item?.productId,
                            fileName: image?.name,
                            fileContent: image?.content
                        });
                    }
                }
            }
        }
        setProductImages([...productImages, ...newProductImages]);
    };

    const getUserInfo = () => {
        getUserDetails().then(response => {
            setUserDetails(response.data as UserData);
        })

    }

    const getImage = (productId: string): string => {
        const image = productImages?.find(x => x.productId === productId) as OrderItemImage;
        if (image) {
            return decodeImageService(image?.fileContent, image?.fileName) as string;
        }
        return "";
    };

    useEffect(() => {
        getUserInfo();
    }, [order?.clientId])


    return (
        <div className={styles.main_container}>
            <p className={styles.title}>{t("myAccountInfo.orders.orderDetails.title") + " #" + order?.referenceNumber}</p>
            <div className={styles.basic_info}>
                <div>
                    <p className={styles.subtitle}>{t('myAccountInfo.orders.status')}</p>
                    <p className={styles.basic_data}>{t(`orderStatuses.${order?.status?.toLocaleLowerCase()}`)}</p>
                </div>
                <div>
                    <p className={styles.subtitle}>{t('myAccountInfo.orders.date')}</p>
                    <p className={styles.basic_data}>{dayjs(order?.creationDate).format('DD/MM/YYYY')}</p>
                </div>
            </div>
            <div className={styles.table_container}>
                <table>
                    <thead>
                        <tr>
                            <th className={`${styles.column} ${styles.product_column}`}>{t("cart.product")}</th>
                            <th className={styles.column}>{t("cart.price")}</th>
                            <th className={styles.column}>{t("cart.quantity")}</th>
                            <th className={styles.column}>{t("cart.totalPrice")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.items && order?.items?.map((item: OrderItem, index: number) => (
                                <tr key={index} >
                                    <td className={`${styles.column} ${styles.product_column}`}>
                                        <div className={styles.image_container}>
                                            <img src={getImage(item.productId)} />
                                        </div>
                                        <div>
                                            <a onClick={() => navigate(`../../product-details/${item?.productId}/${item.productId}`, { replace: true })}>{item.productName}</a>
                                        </div>
                                    </td>
                                    <td className={styles.column}>
                                        {item?.netPrice?.toFixed(2)} {order.cacheData?.currency?.name}
                                    </td>
                                    <td className={styles.column}>
                                        {item.quantity}
                                    </td>
                                    <td className={styles.column}>
                                        {(item?.quantity * item?.netPrice)?.toFixed(2)} {order.cacheData?.currency?.name}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                <div className={styles.summary_row}>
                    <p className={styles.summary_label}>{t('myAccountInfo.orders.orderDetails.products')}</p>
                    <p className={styles.summary_value}>{(order?.totalPrice)?.toFixed(2)} {order?.cacheData?.currency?.name}</p>
                </div>
                <div className={styles.summary_row}>
                    <p className={styles.summary_label}>{t('myAccountInfo.orders.orderDetails.delivery')}</p>
                    <p className={styles.summary_value}>{(delivery?.price ?? 0).toFixed(2)} {order?.cacheData?.currency?.name}</p>
                </div>
                <div className={styles.summary_row}>
                    <p className={styles.summary_label}>{t('myAccountInfo.orders.orderDetails.together')}</p>
                    <p className={styles.summary_value}>
                        {(Number(order?.totalPrice) + Number(delivery?.price ?? 0)).toFixed(2)} {order?.cacheData?.currency?.name}
                    </p>
                </div>
                <div className={styles.footer}>
                    <p className={styles.subtitle}>{t('myAccountInfo.orders.orderDetails.info')}</p>
                    <div className={styles.order_info_container}>
                        <div className={styles.order_info_section}>
                            <p className={styles.order_info_section_title}>{t('myAccountInfo.orders.orderDetails.clientInfo')}</p>
                            <p>{userDetails?.user?.firstName + " " + userDetails?.user?.lastName}</p>
                            <p>{userDetails?.user?.email}</p>
                            <p>{userDetails?.contact?.phone}</p>
                        </div>
                        <div className={styles.order_info_section}>
                            <p className={styles.order_info_section_title}>{t('myAccountInfo.orders.orderDetails.deliveryMethod')}</p>
                            <p>{order?.cacheData?.deliveryMethodName}</p>
                        </div>
                        <div className={styles.order_info_section}>
                            <p className={styles.order_info_section_title}>{t('myAccountInfo.orders.address')}</p>
                            <div className={styles.address_container}>
                                <p>{order?.address?.streetName + " " + order?.address?.buildingNumber}</p>
                                <p>{order?.address?.postalCode + " " + order?.address?.city}</p>
                                <p>{order?.address?.region}</p>
                            </div>
                        </div>
                        <div className={styles.order_info_section}>
                            <p className={styles.order_info_section_title}>{t('myAccountInfo.orders.orderDetails.paymentMethod')}</p>
                            <p>{order?.cacheData?.paymentMethodName}</p>
                        </div>
                    </div>
                </div>
                <button className={styles.return_button} onClick={() => navigate("/client-panel/orders")}>
                    {t("myAccountInfo.orders.orderDetails.returnToOrders")}
                </button>
            </div>
        </div>

    )
}
