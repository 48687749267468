import { useState, useEffect } from 'react';
import { t } from 'i18next';
import styles from './InfoContainer.module.scss';

export default function InfoContainer() {
    const basketIcon = require('../../../../assets/icons/koszyk.svg').default;
    const bagIcon = require('../../../../assets/icons/torba.svg').default;
    const carIcon = require('../../../../assets/icons/delivery.svg').default;
    const appleIcon = require('../../../../assets/icons/smacznego.svg').default;

    const items = [
        {
            href: '/selling-sessions',
            icon: basketIcon,
            alt: 'Basket',
            topText: t('productsPage.basketTopInfo'),
            bottomText: t('productsPage.basketBottomInfo'),
        },
        {
            href: '/producers',
            icon: bagIcon,
            alt: 'Bag',
            topText: t('productsPage.bagTopInfo'),
            bottomText: t('productsPage.bagBottomInfo'),
        },
        {
            href: '/pickup-methods',
            icon: carIcon,
            alt: 'Car',
            topText: t('productsPage.carTopInfo'),
            bottomText: t('productsPage.carBottomInfo'),
        },
        {
            href: '/contact',
            icon: appleIcon,
            alt: 'Apple',
            topText: t('productsPage.appleTopInfo'),
            bottomText: t('productsPage.appleBottomInfo'),
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 767);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const goToNext = () => {
        if (isMobile) {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }
    };

    const goToPrev = () => {
        if (isMobile) {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
        }
    };

    return (
        <div className={styles.info_container}>
            {isMobile && (
                <div className={styles.button_container}>
                    <button onClick={goToPrev} className={styles.arrow_left}>
                        &lt;
                    </button>
                </div>
            )}

            {isMobile ? (
                <div className={styles.carousel}>
                    <div className={styles.carousel_wrapper}>
                        <a
                            key={currentIndex}  // key prop triggers re-render on currentIndex change
                            href={items[currentIndex].href}
                            className={styles.item}
                        >
                            <img
                                src={items[currentIndex].icon}
                                alt={items[currentIndex].alt}
                            />
                            <div className={styles.item_description}>
                                <div className={styles.top_part}>
                                    {items[currentIndex].topText}
                                </div>
                                <div className={styles.bottom_part}>
                                    {items[currentIndex].bottomText}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            ) : (
                <div className={styles.carousel}>
                    <div className={styles.carousel_wrapper}>
                        {items.map((item, index) => (
                            <a
                                key={index}
                                href={item.href}
                                className={styles.item}
                            >
                                <img src={item.icon} alt={item.alt} />
                                <div className={styles.item_description}>
                                    <div className={styles.top_part}>
                                        {item.topText}
                                    </div>
                                    <div className={styles.bottom_part}>
                                        {item.bottomText}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            )}

            {isMobile && (
                <div className={styles.button_container}>
                    <button onClick={goToNext} className={styles.arrow_right}>
                        &gt;
                    </button>
                </div>
            )}
        </div>
    );
}
