import React, { useEffect, useState } from 'react'
import styles from './SellingSessions.module.scss';
import { useTranslation } from 'react-i18next';
import { SellingSession, SellingSessionPaginated } from './models';
import { getSellingSessionsPaginatedService } from './service';
import { PickupPoint } from '../../shared/map/models';
import { getPickupPoints } from '../../shared/map/services';
import uuid from 'react-uuid';
import Paginator from '../../shared/paginator/Paginator';
import { formatDateTimeService } from '../../shared/services';
import SelectList from '../../shared/select-list/SelectList';
import { usePluginStore } from 'react-pluggable';
import { PluginEvent } from '../../plugins/Extensions/pluginEvent';

export default function SellingSessions() {
    const { t, i18n } = useTranslation('translation');
    const pluginStore: any = usePluginStore();
    let Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    const defaultValue = localStorage.getItem("pickupPointsFilter") ? localStorage.getItem("pickupPointsFilter")?.toString() : "any";
    const [sellingSessionsObject, setSellingSessionsObject] = useState<SellingSessionPaginated>();
    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [selectedPickupPointId, setSelectedPickupPointId] = useState<string | null>(defaultValue as string);

    useEffect(() => {
        pluginStore.addEventListener("SelectedPickupPointChanged", handlePickupPointChanged);
        return () => {
            pluginStore.removeEventListener("SelectedPickupPointChanged", handlePickupPointChanged);
        };
    }, [pluginStore])

    useEffect(() => {
        fetchSellingSessions();
    }, [selectedPickupPointId, currentPage, perPage]);

    const fetchSellingSessions = () => {
        getSellingSessionsPaginatedService(currentPage + 1, perPage, selectedPickupPointId).then((response: any) => {
            let result = response.data as SellingSessionPaginated;
            setSellingSessionsObject(result);
            setSellingSessions(result?.items);
        });
    }

    const handlePickupPointChanged = (pluginEvent: PluginEvent) => {
        const id = pluginEvent?.data?.id;
        setSelectedPickupPointId(id);
        fetchSellingSessions();
    }

    const handlePageClick = (selectedPage: any) => {
        setCurrentPage(selectedPage.selected);
    };

    const handlePerPageChange = (event: any) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.pickup_points_container}>
                <Renderer placement={"page:selectPickupPoint"} />
            </div>
            <div className={styles.table_container}>
                <div className={styles.table_wrapper}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>{t("sellingSessions.table.name")}</th>
                                <th>{t("sellingSessions.table.startOrderingDate")}</th>
                                <th>{t("sellingSessions.table.endOrderingDate")}</th>
                                <th>{t("sellingSessions.table.startPickupDate")}</th>
                                <th>{t("sellingSessions.table.endPickupDate")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sellingSessions && sellingSessions?.map((ss: SellingSession, index: number) => (
                                    <tr key={uuid()}>
                                        <td>{ss.name}</td>
                                        <td>{formatDateTimeService(ss.startOrderingDate)}</td>
                                        <td>{formatDateTimeService(ss.endOrderingDate)}</td>
                                        <td>{formatDateTimeService(ss.startPickupDate)}</td>
                                        <td>{formatDateTimeService(ss.endPickupDate)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <div className={styles.paginator_container}>
                    <Paginator
                        perPage={perPage}
                        onPageClick={handlePageClick}
                        onPerPageChange={handlePerPageChange}
                        currentPage={currentPage ? currentPage : 0}
                        totalPages={sellingSessionsObject ? sellingSessionsObject?.totalPages as number : 0} />
                </div>
            </div>
        </div>
    )
}
