import { useEffect, useState } from "react";
import styles from "./PhotoGallery.module.scss";
import { decodeImageService } from "../../services";
import { AttachmentFile } from "../../models/attachment-file.model";

interface PhotoGalleryProps {
    photos: AttachmentFile[];
}

export default function PhotoGallery({ photos }: PhotoGalleryProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (index: number) => {
        setCurrentIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const goToNextPhoto = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    };

    const goToPreviousPhoto = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
    };

    useEffect(() => {
        setCurrentIndex(0);
    }, [photos]);

    return (
        <div className={styles.container}>
            <div className={styles.main_photo}>
                <img
                    src={decodeImageService(photos[0]?.content as string, photos[0]?.name as string)}
                    alt="Main Photo"
                    onClick={() => openModal(0)}
                />
            </div>
            <div className={styles.other_photos}>
                {photos.length > 1 && photos.slice(1, 5).map((photo, index) => (
                    <img
                        key={index}
                        src={decodeImageService(photo?.content as string, photo?.name as string)}
                        alt={`Photo ${index + 1}`}
                        onClick={() => openModal(index + 1)}
                    />
                ))}
            </div>

            {modalOpen && (
                <div className={styles.modal}>
                    <div className={styles.modal_content}>
                        {currentIndex > 0 && (
                            <button className={styles.prev_button} onClick={goToPreviousPhoto}>≺</button>
                        )}
                        <button className={styles.close_button} onClick={closeModal}>X</button>
                        <img
                            src={decodeImageService(photos[currentIndex]?.content as string, photos[currentIndex]?.name as string)}
                            alt={`Photo ${currentIndex + 1}`}
                            className={styles.modal_image}
                        />

                        {currentIndex < photos.length - 1 && (
                            <button className={styles.next_button} onClick={goToNextPhoto}>≻</button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

