import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styles from './ButtonArrowForward.module.scss';

export default function ButtonArrowForward(props: any) {

    const handleClickEvent = (): void => {
        if (props?.handleClick) {
            props.handleClick();
        }
    }

    return (
        <div className={!props.isClickable ? styles.main_container : `${styles.main_container} ${styles.clickable}`} onClick={handleClickEvent}>
            <KeyboardArrowRightIcon fontSize='small' className={styles.arrow} />
        </div>
    )
}
