import { useEffect, useState } from "react";
import { Producer } from "../products-page/components/producers-list/models";
import styles from "./ProducersPage.module.scss";
import { getPaginatedProducers } from "./services";
import Paginator from "../../shared/paginator/Paginator";
import { ProducerPaginated } from "./models";
import ProducerCard from "../producer-card/ProducerCard";

export default function ProducersPage() {
    const [producers, setProducers] = useState<Producer[]>();
    const [paginatedProducers, setPaginatedProducers] = useState<ProducerPaginated>();
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);

    useEffect(() => {
        getProducers();
    }, [currentPage, perPage]);

    const getProducers = () => {
        getPaginatedProducers(currentPage, perPage).then((response: any) => { setPaginatedProducers(response.data) });
    }

    const handlePageClick = (selectedPage: any) => {
        setCurrentPage(selectedPage.selected);
    };

    const handlePerPageChange = (event: any) => {
        setPerPage(parseInt(event.target.value));
        setCurrentPage(0);
    };


    return (
        <div className={styles.main_container}>
            <div className={styles.cards_container}>
                {paginatedProducers?.items && paginatedProducers?.items?.map((producer: Producer) => (
                    <ProducerCard key={producer.id} producer={producer}></ProducerCard>
                ))}
            </div>
            {
                (paginatedProducers && paginatedProducers.items?.length > 0) &&
                <div className={styles.paginator_container}>
                    <Paginator
                        perPage={perPage}
                        onPageClick={handlePageClick}
                        onPerPageChange={handlePerPageChange}
                        totalPages={paginatedProducers ? paginatedProducers?.totalPages as number : 0}
                        currentPage={currentPage ? currentPage : 0} />
                </div>
            }
        </div>
    )
}
