import { useEffect, useState } from 'react';
import styles from './PostalCodeCheck.module.scss';
import { DeliveryMethod } from '../../../order/models';
import { useTranslation } from 'react-i18next';

interface PostalCodeCheckProps {
    deliveryMethod: DeliveryMethod;
    buyAction: any;
}

export default function PostalCodeCheck({ deliveryMethod, buyAction }: PostalCodeCheckProps) {
    const [deliveryPostalCode, setDeliveryPostalCode] = useState<string>("");
    const [isPostalCodeValid, setIsPostalCodeValid] = useState<boolean | null>(null);
    const { t, i18n } = useTranslation('translation');

    useEffect(() => {
        checkDeliveryMethod();
        setPostalCodeFromLocalStorage();
    }, [])

    const checkDeliveryMethod = () => {
        if (deliveryMethod.code === "PICKUP_POINT" || deliveryMethod.isAvailableEverywhere) {
            setIsPostalCodeValid(true);
        }
    }

    const setPostalCodeFromLocalStorage = () => {
        const localStorageItem = localStorage.getItem('postalCode');
        if (localStorageItem !== null) {
            setDeliveryPostalCode(localStorageItem);
        }
    }

    const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeliveryPostalCode(e.target.value);
    };

    const checkPostalCode = () => {
        const trimmedPostalCode = deliveryPostalCode.trim().replace(/-/g, '');
        const formattedAvailablePostalCodes = deliveryMethod.availableInPostalCodes.map(code => code.replace(/-/g, '').trim());
        const isValid = formattedAvailablePostalCodes.includes(trimmedPostalCode);
        if (isValid) {
            localStorage.setItem('postalCode', deliveryPostalCode);
        }
        setIsPostalCodeValid(isValid);
    };

    return (
        <div className={styles.container}>
            {!deliveryMethod.isAvailableEverywhere &&
                <>

                    <div className={styles.first_row}>
                        <div className={styles.text}>{t("cart.postalCode.enterPostalCode")}:</div>
                        <input
                            type="text"
                            value={deliveryPostalCode}
                            onChange={handlePostalCodeChange}
                        />
                        <button onClick={checkPostalCode}>{t("common.check")}</button>
                    </div>
                    <div className={styles.second_row}>
                        {isPostalCodeValid === true && (
                            <div className={styles.green_text}>{t("cart.postalCode.available")}</div>
                        )}
                        {isPostalCodeValid === false && (
                            <div className={styles.red_text}>{t("cart.postalCode.notAvailable")}</div>
                        )}
                    </div>
                </>
            }
            <div className={styles.third_row}>
                <button onClick={buyAction} disabled={isPostalCodeValid !== true}>Dalej</button>
            </div>
        </div>
    );
}
