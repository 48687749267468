import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Search.module.scss';
import { Category } from '../products-categories/models/Category.model';
import axios from 'axios';
import { RootState } from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { Filters } from '../../../../models/filters.model';
import { setFilters } from '../../../../store/actions';
import { SORT_BY_CONSTANTS } from '../../../../shared/constants/sort-by.constants';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Search(props: any) {
    const productFilters = useSelector((state: RootState) => state.filters);
    const dispatch = useDispatch();

    const magnifierIcon = require('../../../../assets/icons/magnifier_white.svg').default;
    const [mainCategories, setMainCategories] = useState<Category[]>([]);
    const { t, i18n } = useTranslation('translation');
    const [searchProductName, setSearchProductName] = useState('');
    const [sortBy, setSortBy] = useState<string>(productFilters.sortBy || '');
    const guidEmpty = '00000000-0000-0000-0000-000000000000';
    const isWideScreen = useMediaQuery('(min-width:768px)');
    const navigate = useNavigate();
    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        if (sortBy !== null && sortBy !== '') {
            handleSearchClick();
        }
    }, [sortBy]);

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`categories/tree/${guidEmpty}`);
            const categories: Category[] = response?.data;
            setMainCategories(categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleSearchClick = () => {
        const filters: Filters = {
            pickupPointId: productFilters.pickupPointId,
            categoryId: props.categoryId || null,
            productName: searchProductName || null,
            sortBy: sortBy || null
        };
        dispatch(setFilters(filters));

        if (props.searchedClicked) {
            props.searchedClicked();
        }
    };

    const handleCategorySelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        localStorage.setItem('categoryId', event.target.value);
        props.onSelectCategory(event.target.value)
    };

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortBy(event.target.value);
    };

    const handleSearchPhraseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchProductName(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <div className={styles.search_and_filter_wrapper}>
            <div className={styles.search_wrapper}>
                {isWideScreen &&
                    <select
                        onChange={handleCategorySelect}
                        value={
                            props.categoryId !== null && props.categoryId !== undefined ? props.categoryId : ''
                        }
                    >
                        <option value=''>Wszystko</option>
                        {mainCategories.map((category: Category, index: number) => (
                            <option key={index} value={category.id}>
                                {t(category.name)}
                            </option>
                        ))}
                    </select>
                }

                <input
                    type='text'
                    placeholder='...'
                    onChange={handleSearchPhraseChange}
                    defaultValue={productFilters.productName || ''}
                    onKeyDown={handleKeyDown}
                />

                <button onClick={handleSearchClick}>
                    <img src={magnifierIcon} alt='Magnifier' />
                </button>
            </div>

            {isWideScreen &&
                <select
                    onChange={handleSortChange}
                    className={styles.sortBySelect}
                    value={sortBy}
                >
                    <option value='' disabled hidden>Sortuj wg</option>
                    <option value='none'>Brak</option>
                    {SORT_BY_CONSTANTS.map((value: string, index: number) => (
                        <option key={index} value={value}>
                            {t('searchBar.sortByConstants.' + value)}
                        </option>
                    ))}
                </select>
            }
        </div>
    );
}
