import { useEffect, useState } from 'react';
import { AttachmentFile } from '../../../shared/models/attachment-file.model';
import { getImageById } from '../../../shared/services/get-attachments.service';
import styles from './CartItemImage.module.scss';
import { decodeImageService, getProductImagesService } from '../../../shared/services';

interface CartItemImageProps {
    productId: string;
}

export default function CartItemImage({ productId }: CartItemImageProps) {
    const [image, setImage] = useState<AttachmentFile>();
    const guidEmpty = '00000000-0000-0000-0000-000000000000';

    const fetchProductImage = () => {
        getProductImagesService(productId).then((response) => {
            if (response?.data) {
                const filteredImages = response.data.filter((image: { content: string }) => image.content !== '');
                setImage(filteredImages[0] as AttachmentFile);
            }
        });
    };

    useEffect(() => {
        if (productId !== guidEmpty && productId !== null) {
            fetchProductImage();
        }
    }, [productId]);

    return (
        <div className={styles.image_container}>{image && <img className={styles.producer_image} src={decodeImageService(image.content, image.name)} />}</div>
    );
}
