import { NavigateFunction } from "react-router";
import { UserLogin } from "../login/models";
import axios from "axios";
import * as axiosConfiguration from "../../configuration/axios-configuration/axios.token-configuration";

export const logIn = (userCredentials: UserLogin, navigate: NavigateFunction, redirectTo: string): void => {
    localStorage.removeItem("token");
    axiosConfiguration.setAuthToken();
    axios.post("Auth/Login", { ...userCredentials })
        .then((response) => {
            if (response) {
                const token = response.data.token;
                localStorage.setItem("token", token);
                axiosConfiguration.setAuthToken();
                navigate(redirectTo);
            }
        });
}

export const logOut = (navigate: NavigateFunction): void => {
    localStorage.removeItem("token");
    axiosConfiguration.setAuthToken();
    navigate("/");
}

export const checkLogInStatus = (): boolean => {
    return localStorage.getItem("token") ? true : false;
}