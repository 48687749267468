import parse from 'html-react-parser';
import styles from './ProductIngredients.module.scss';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getIngredientsForProduct } from '../../../components/product-details-view/services';

const ProductIngredients = () => {
    const { t, i18n } = useTranslation('translation');
    const [activeProductIngredients, setActiveProductIngredients] = useState<string>();
    const { productId } = useParams();

    const getIngredientsForActiveProduct = async (): Promise<void> => {
        const response = await getIngredientsForProduct(productId as string);
        setActiveProductIngredients(response?.data as string);
    };

    useEffect(() => {
        getIngredientsForActiveProduct();
    }, []);

    return (<>
        {activeProductIngredients && activeProductIngredients !== "" && (
            <div className={styles.product_description}>
                {parse(activeProductIngredients)}
            </div>
        )}

    </>);
};

export default ProductIngredients;