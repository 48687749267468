import { useEffect, useState } from 'react';
import styles from './ResetPassword.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as resetPasswordService from '../../../services/reset-password.service';
import { toast } from 'react-toastify';
import { ResetPasswordDataDto } from '../../models';

const setNewPasswordPageUrl = process.env.REACT_APP_RESET_PASSWORD_PAGE_URL as string;

export default function ResetPassword() {

    const [displaySuccess, setDisplaySuccess] = useState<boolean>(false);
    const [displayError, setDisplayError] = useState<boolean>(false);
    const { t, i18n } = useTranslation('translation');

    function sendLink(values: any, actions: any, successMessage: string, errorMessage: string) {
        const requestModel = {
            email: values.email,
            url: setNewPasswordPageUrl
        } as ResetPasswordDataDto;

        resetPasswordService.sendLink(requestModel)
            .then((response) => {
                if (response) {
                    setDisplaySuccess(true);
                    setDisplayError(false);
                    actions.resetForm();
                }
            })
            .catch((error) => {
                setDisplaySuccess(false);
                setDisplayError(true);
            });
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.main_container}>
                <div className={styles.title}>
                    {t("loginForm.resetPassword.title")}
                </div>
                <div className={styles.info}>
                    {t("loginForm.resetPassword.enterEmail")}
                </div>
                <Formik initialValues={{ email: "" }} onSubmit={(values, actions) =>
                    sendLink(values, actions, t("resetPasswordForm.successMessage"), "sprawdź czy poprawnie wpisałeś email")
                }>
                    {({ values, handleChange, handleBlur }) => (
                        <Form className={styles.form_container} onMouseEnter={() => { }}>
                            <div className={styles.form_row}>
                                <p className={styles.row_name}>{t("resetPasswordForm.email")}</p>
                                <input
                                    className={styles.row_input}
                                    name="email"
                                    type="text"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            {displaySuccess && <div className={styles.successText}>{t("resetPasswordForm.successMessage")}</div>}
                            {displayError && <div className={styles.errorText}> {t("resetPasswordForm.errorMessage")}</div>}
                            <div className={styles.link_container}>
                                <a href={"login"}>{t("resetPasswordForm.goBackToLoginPage")}</a>
                            </div>
                            <div className={styles.form_row}>
                                <button className={styles.submit_button} type="submit">{t("resetPasswordForm.sendLink")}</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}