import { useEffect, useState } from "react";
import { getDimensionedImage } from "../../../../../shared/services/get-attachments.service";
import styles from './ProducerImage.module.scss';
import { EMPTY_GUID, SMALL_PRODUCER_CARD_HEIGHT, SMALL_PRODUCER_CARD_WIDTH } from "../../../../../shared/constants/images-resolution.constants";

interface ProducerImageProps {
    fileId: string
    producerId: string;
}

export default function SmallProducerImage({ fileId, producerId }: ProducerImageProps) {

    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (fileId !== null && fileId !== undefined && fileId !== EMPTY_GUID) {
            getDimensionedImage(fileId, SMALL_PRODUCER_CARD_WIDTH, SMALL_PRODUCER_CARD_HEIGHT).then((response) => {
                if (response) {
                    setImage(response)
                }
            })
        }
    }, [fileId])


    return (
        <div className={styles.image_container}>
            {image ? (
                <a href={`/producer-details-view/${producerId}`}>
                    <img
                        className={styles.producer_image} src={image}
                    />
                </a>
            )
                :
                <a href={`/producer-details-view/${producerId}`}>
                    <div className={styles.grey_circle}>
                    </div>
                </a>
            }
        </div>
    );
}