import { IPlugin, PluginStore, Event } from "react-pluggable";
import PickupPointsFilters from "./components/PickupPointsFilters/PickupPointsFilters";
import AvaliableIn from "./components/AvaliableIn/AvaliableIn";
import axios from "axios";
import { ProductsIdsWithPickupPoints } from "../models/productsIdsWithPickupPoints.model";
import { setProductsWithPickupPoints } from "../store/actions";
import { Store } from "redux";

class FilteringByPickupPointPlugin implements IPlugin {
    pluginStore!: PluginStore;
    store!: Store;

    constructor(store: Store) {
        this.store = store
    }

    getPluginName(): string {
        return "FilteringByPickupPoint@1.0.0";
    }

    getDependencies(): string[] {
        return [];
    }

    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }

    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "categories:before", () => (
            <PickupPointsFilters></PickupPointsFilters>
        ));

        this.pluginStore.addEventListener("getProductsExtendedInfo", (event: any) => {
            this.getProductsExtendedByPickupPoints();
        });
    }

    getProductsExtendedByPickupPoints() {
        axios.get<ProductsIdsWithPickupPoints>("products/pickup-points").then((response: any) => {
            if (response) {
                this.store.dispatch(setProductsWithPickupPoints(response?.data));
                response?.data.forEach((x: any) => {
                    this.pluginStore.executeFunction("Renderer.add", "productCard" + x.productId + ":inside", () => (
                        <AvaliableIn productId={x.productId}></AvaliableIn>
                    ));
                })
            }
        });
    }
    deactivate(): void {

    }
}

export default FilteringByPickupPointPlugin;