import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getDimensionedImage } from '../../shared/services/get-attachments.service';
import { PRODUCER_CARD_WIDTH, PRODUCER_CARD_HEIGHT, EMPTY_GUID } from '../../shared/constants/images-resolution.constants';
import * as exifr from 'exifr';
import styles from './ProducerCard.module.scss';

const isFirefox = () => {
    return typeof navigator !== 'undefined' && /firefox/i.test(navigator.userAgent);
};

export default function ProducerCard(props: any) {
    const { t } = useTranslation("translation");
    const navigate = useNavigate();
    const [image, setImage] = useState<string>();
    const [rotation, setRotation] = useState<number>(0);

    const handleProductClick = () => {
        navigate("/producer-details-view/" + props.producer?.id);
    };

    useEffect(() => {
        if (props.producer.fileId && props.producer.fileId !== EMPTY_GUID) {
            getDimensionedImage(props.producer.fileId, PRODUCER_CARD_WIDTH, PRODUCER_CARD_HEIGHT).then(async (response) => {
                if (response) {
                    const img = new Image();
                    img.src = response;
                    img.onload = async () => {
                        if (isFirefox()) {
                            try {
                                const exifData = await exifr.parse(img.src);
                                const orientation = exifData?.Orientation;
                                switch (orientation) {
                                    case "Rotate 90 CW":
                                        setRotation(90);
                                        break;
                                    case "Rotate 180 CW":
                                        setRotation(180);
                                        break;
                                    case "Rotate 270 CW":
                                        setRotation(270);
                                        break;
                                    case "Horizontal (normal)":
                                        setRotation(0);
                                        break;
                                    default:
                                        setRotation(0);
                                        break;
                                }
                            } catch (error) {
                                console.error("Error reading EXIF data:", error);
                                setRotation(0);
                            }
                        }
                    };
                    setImage(response);
                }
            });
        }
    }, [props.producer]);

    return (
        <div className={styles.card_container}>
            <div className={styles.image_icons_container}>
                {image ? (
                    <img
                        src={image}
                        alt={props.producer.imageName}
                        onClick={handleProductClick}
                        style={{
                            transform: `rotate(${rotation}deg)`,
                        }}
                        className={styles.producer_image}
                    />
                ) : (
                    <div className={styles.image_placeholder} onClick={handleProductClick}></div>
                )}
            </div>

            <div className={styles.producer_name_container}>
                <h6 onClick={handleProductClick}>{props.producer?.name}</h6>
            </div>
            <button onClick={handleProductClick} className={styles.more_button}>
                {t("producer.card.more")}
            </button>
        </div>
    );
}
