import { Country, DeliveryMethod, OrderModel } from '../../models';
import styles from './ClientData.module.scss';
import { useEffect, useState } from 'react';
import { getCountries, getDeliveryMethodById, getPickupPointById } from '../../services';
import { PickupPoint } from '../../../../shared/map/models';
import { getSellingSessionsPaginatedService } from '../../../selling-sessions/service';
import { SellingSession } from '../../../selling-sessions/models';
import { formatDateTimeService } from '../../../../shared/services';
import { useTranslation } from 'react-i18next';
import { Address } from '../../../../auth/register/models';

interface ClientDataProps {
    orderModel: OrderModel;
    pickupPointMethodChecked: boolean;
    otherDeliveryAddressChecked: boolean;
}

export default function ClientData({ orderModel, pickupPointMethodChecked, otherDeliveryAddressChecked }: ClientDataProps) {
    const [countries, setCountries] = useState<Country[]>([]);
    const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>();
    const [pickupPoint, setPickupPoint] = useState<PickupPoint>();
    const [sellingSessions, setSellingSessions] = useState<SellingSession[]>();
    const [activeSellingSessions, setActiveSellingSessions] = useState<SellingSession[]>();
    const [address, setAddress] = useState<Address | undefined>();
    const [t, i18] = useTranslation();

    useEffect(() => {
        fetchCountries();
        fetchDeliveryMethod();
        handleCheckAddress();
    }, []);

    useEffect(() => {
        if (deliveryMethod !== null && deliveryMethod !== undefined) {
            const pickupPointId = localStorage.getItem('pickupPointsFilter');
            if (pickupPointId !== '' && pickupPointId !== null) {
                fetchPickupPointIfNeeded(pickupPointId);
                getSellingSessionsPaginatedService(1, 20, pickupPointId, deliveryMethod.id).then((response: any) => {
                    setSellingSessions(response.data?.items);
                });
            }
        }
    }, [deliveryMethod])

    useEffect(() => {
        if (sellingSessions !== null && sellingSessions !== undefined) {
            const now = new Date();

            const activeSessions = sellingSessions.filter(session =>
                now >= new Date(session.startOrderingDate) && now <= new Date(session.endOrderingDate)
            );
            setActiveSellingSessions(activeSessions);
        }
    }, [sellingSessions]);

    useEffect(() => {
        if (pickupPoint && pickupPoint !== null) {
            setAddress(pickupPoint.address);
        }
    }, [pickupPoint])


    const handleCheckAddress = () => {
        if (orderModel && !pickupPointMethodChecked && !pickupPoint) {
            if (otherDeliveryAddressChecked && orderModel.orderAddress && orderModel.orderAddress.streetName) {
                setAddress(orderModel.orderAddress);
            }
            else {
                setAddress(orderModel.userAddress);
            }
        }
    }

    const fetchCountries = () => {
        getCountries().then((response) => {
            if (response.data) {
                setCountries(response.data as Country[]);
            }
        });
    }

    const fetchDeliveryMethod = () => {
        const deliveryMethodId = localStorage.getItem('orderDeliveryMethodId');
        if (deliveryMethodId && deliveryMethodId !== '') {
            getDeliveryMethodById(deliveryMethodId).then((response) => {
                if (response.data) {
                    setDeliveryMethod(response.data as DeliveryMethod);
                }
            })
        }
    }

    const fetchPickupPointIfNeeded = (pickupPointId: string) => {
        if (pickupPointId && pickupPointId !== '') {
            getPickupPointById(pickupPointId).then((response) => {
                if (response) {
                    setPickupPoint(response.data as PickupPoint);
                }
            });
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <div className={styles.header}>{t("myAccountInfo.orders.orderDetails.clientInfo")}</div>
                <div className={styles.rows}>
                    <div className={styles.first_row}>
                        <div>{orderModel.userFirstName} {orderModel.userLastName}</div>
                    </div>
                    <div className={styles.third_row}>
                        <div>
                            {orderModel.userAddress.streetName} {orderModel.userAddress.buildingNumber}{orderModel.userAddress.flatNumber !== '' && `/${orderModel.userAddress.flatNumber}`}
                        </div>
                        <div>{orderModel.userAddress.postalCode} {orderModel.userAddress.city}</div>
                        <div>{t(`countriesNames.${countries?.find(x => x.id === orderModel.userAddress.country)?.name}`)}</div>
                    </div>
                    <div className={styles.second_row}>
                        <div>{orderModel.userEmail}</div>
                        <div>{orderModel.userPhoneNumber}</div>
                    </div>
                </div>
            </div>
            <>
                <div className={styles.column}>
                    <div className={styles.header}>{t("myAccountInfo.address.delivery")}</div>
                    <div className={styles.field}>
                        <div className={styles.field_label}>
                            {t("order.deliveryMethod.title")}
                        </div>
                        {deliveryMethod &&
                            <div className={styles.field_content}>
                                &nbsp;{deliveryMethod.name}
                            </div>
                        }
                    </div>
                    <div className={styles.field_label}>{t("myAccountInfo.orders.address")} </div>
                    <div className={styles.rows}>
                        <div>
                            {address?.streetName} {address?.buildingNumber}{address?.flatNumber !== '' && `/${address?.flatNumber}`}
                        </div>
                        <div>{address?.postalCode} {address?.city}</div>
                        <div>{t(`countriesNames.${countries?.find(x => x.id === address?.country)?.name}`)}</div>
                    </div>
                    {activeSellingSessions && activeSellingSessions?.length > 0 &&
                        <div className={styles.third_row}>
                            <div className={styles.field_label}>{t("orderConfirmation.deliveryData.pickupHours")}</div>
                            <div>Od: {formatDateTimeService(activeSellingSessions[0].startPickupDate)}</div>
                            <div>Do: {formatDateTimeService(activeSellingSessions[0].endPickupDate)}</div>
                        </div>
                    }
                </div>
            </>
        </div>
    );
}
