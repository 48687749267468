export const HOW_TO_BUY: string = "HOW_TO_BUY";
export const CONTACT: string = "CONTACT";
export const ABOUT: string = "ABOUT";
export const JOIN_US: string = "JOIN_US";
export const LOGO: string = "LOGO";
export const WEBSITE_REGULATIONS: string = "WEBSITE_REGULATIONS";
export const DELIVERY: string = "DELIVERY";
export const PAYMENTS: string = "PAYMENTS";
export const COLLABORATION: string = "COLLABORATION";
export const PARTNERS: string = "PARTNERS";
export const REGULATIONS: string = "REGULATIONS";
export const PRIVACY_POLICY: string = "PRIVACY_POLICY";
export const PRZELEWY24_REGULATIONS: string = "PRZELEWY24_REGULATIONS";
export const PRZELEWY24_OBLIGATIONS: string = "PRZELEWY24_OBLIGATIONS";
