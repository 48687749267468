import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AddressDetails.module.scss';
import { getUserDetails } from '../my-account-info/services';
import { Country } from '../../../order/models';
import { UserData } from '../my-account-info/models';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { Address } from '../my-account-info/models/address.model';
import WarningIcon from '@mui/icons-material/Warning';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import uuid from "react-uuid";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function AddressDetails() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('translation');

    const [userDetails, setUserDetails] = useState<UserData>();
    const [countries, setCountries] = useState<Country[]>();
    const [mainAddress, setMainAddress] = useState<Address>();
    const [billingAddress, setBillingAddress] = useState<Address>();
    const [showMainAddressForm, setShowMainAddressForm] = useState<boolean>();
    const [showBillingAddressForm, setShowBillingAddressForm] = useState<boolean>();
    const [showAdditionalAddressForm, setShowAdditionalAddressForm] = useState<boolean>();
    const [otherAddresses, setOtherAddresses] = useState<Address[]>([]);
    const [editingAddress, setEditingAddress] = useState<string | null>();
    const [isEditBilling, setIsEditBilling] = useState<boolean>();
    const [isEditMain, setIsEditMain] = useState<boolean>();


    const AddressTile = (address: Address, index = 0, type: string = "ADDITIONAL") => {
        return (
            <div className={styles.section} key={index}>
                <div className={styles.section_data}>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.addressType")}:</p>
                        <p className={styles.data_value}>{address ? t("myAccountInfo.address." + address?.addressType?.toLowerCase()) : ""}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.streetName")}:</p>
                        <p className={styles.data_value}>{address?.streetName}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.buildingNumber")}:</p>
                        <p className={styles.data_value}>{address?.buildingNumber}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.flatNumber")}:</p>
                        <p className={styles.data_value}>{address?.flatNumber}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.postalCode")}:</p>
                        <p className={styles.data_value}>{address?.postalCode}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.city")}:</p>
                        <p className={styles.data_value}>{address?.city}</p>
                    </div>
                    <div className={styles.row}>
                        <p className={styles.data_name}>{t("myAccountInfo.address.country")}:</p>
                        <p className={styles.data_value}>{t(`countriesNames.${countries?.find(x => x.id === address?.country)?.name}`)}</p>
                    </div>
                </div>
                <div className={styles.buttons_container}>
                    {address.addressType !== 'MAIN' && <button onClick={() => deleteAddress(address.id as string)} className={styles.action_button}>
                        <DeleteIcon></DeleteIcon>
                    </button>}

                    <button onClick={() => handleEditButton(address, type)} className={styles.action_button}>
                        <EditIcon></EditIcon>
                    </button>
                </div>
            </div>
        )
    }

    const findCountryByCode = (code: string) => {
        if (countries) {
            const result = countries.find(country => country.code === code)?.id;
            if (!result) return "";
            return result;
        }
    };

    const AddressForm = (isBilling: boolean, formik: any, address: Address | null = null) => {

        if (address) {
            formik.initialValues.id = address.id;
            formik.initialValues.addressType = address.addressType;
            formik.initialValues.country = address.country;
            formik.initialValues.postalCode = address.postalCode;
            formik.initialValues.city = address.city;
            formik.initialValues.streetName = address.streetName;
            formik.initialValues.buildingNumber = address.buildingNumber;
            formik.initialValues.flatNumber = address.flatNumber;
        }
        else {
            formik.initialValues.country = findCountryByCode("PL")
        }


        return (
            <div className={styles.section_data}>
                <form onSubmit={formik.handleSubmit} className={styles.form}>
                    <div className={styles.address_data}>
                        <div className={styles.form_row}>
                            <p className={styles.field_name}>{t('register.streetName')}</p>
                            <input
                                className={`${styles.normal_field} ${formik.errors.streetName && formik.touched.streetName ? styles.errorText : ''}`}
                                name="streetName"
                                value={formik.values.streetName}
                                onChange={formik.handleChange}
                            />

                        </div>
                        <div className={styles.double_field_row}>
                            <div className={styles.form_row}>
                                <p className={styles.first_field_name}>{t('register.buildingNumber')}</p>
                                <input
                                    className={`${styles.equal_double_row_input} ${formik.errors.buildingNumber && formik.touched.buildingNumber ? styles.error : ''}`}
                                    name="buildingNumber"
                                    value={formik.values.buildingNumber}
                                    onChange={formik.handleChange}
                                />

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.flat_field_name}>{t('register.flatNumber')}</p>
                                <input
                                    className={`${styles.equal_double_row_input} ${formik.errors.flatNumber && formik.touched.flatNumber ? styles.error : ''}`}
                                    name="flatNumber"
                                    value={formik.values.flatNumber}
                                    onChange={formik.handleChange}
                                />

                            </div>
                        </div>
                        <div className={styles.double_field_row}>
                            <div className={styles.form_row}>
                                <p className={styles.first_field_name}>{t('register.postalCode')}</p>
                                <input
                                    className={`${styles.first_double_row_input} ${formik.errors.postalCode && formik.touched.postalCode ? styles.error : ''}`}
                                    name="postalCode"
                                    value={formik.values.postalCode}
                                    onChange={formik.handleChange}
                                />

                            </div>
                            <div className={styles.form_row}>
                                <p className={styles.second_field_name}>{t('register.city')}</p>
                                <input
                                    className={`${styles.second_double_row_input} ${formik.errors.city && formik.touched.city ? styles.error : ''}`}
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                />
                            </div>
                        </div>
                        <div className={styles.form_row}>
                            <p className={styles.field_name}>{t('register.country')}</p>
                            <select
                                name="country"
                                className={`${styles.country_dropdown} ${formik.errors.country && formik.touched.country ? styles.error : ''}`}
                                value={formik.values.country}
                                onChange={formik.handleChange}
                            >
                                <option disabled></option>
                                {countries && countries?.map((country: any, index: number) => (
                                    <option key={index} value={country?.id}>{t(`countriesNames.${country?.name}`)}</option>
                                ))}
                            </select>

                        </div>
                        <div className={styles.form_row}>
                            <button type="submit" className={styles.save_button}>{t('myAccountInfo.userDetails.save')}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const mainAddressInitialValues = {
        id: '',
        addressType: 'MAIN',
        country: '',
        postalCode: '',
        city: '',
        streetName: '',
        buildingNumber: '',
        flatNumber: '',
    };

    const billingInitialValues = {
        id: '',
        addressType: 'BILLING',
        country: '',
        postalCode: '',
        city: '',
        streetName: '',
        buildingNumber: '',
        flatNumber: '',
    };

    const additionalInitialValues = {
        id: '',
        addressType: 'DELIVERY',
        country: '',
        postalCode: '',
        city: '',
        streetName: '',
        buildingNumber: '',
        flatNumber: '',
    };


    const validationSchema = yup.object({
        streetName: yup.string().trim().required().matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ0-9\s]*$/, 'Street name must not contain special characters'),
        buildingNumber: yup.string()
            .required('Building number is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Building number must not contain special characters'),
        flatNumber: yup.string()
            .matches(/^[a-zA-Z0-9\s]*$/, 'Flat number must not contain special characters'),
        postalCode: yup.string()
            .required('Postal code is required'),
        city: yup.string()
            .required('City is required')
            .matches(/^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚżŻźŹ\s]*$/, 'City must contain only letters'),
        country: yup.string()
            .required('Country is required')
    });




    const mainAddressFormik = useFormik({
        initialValues: mainAddressInitialValues,
        validationSchema,
        onSubmit: values => {
            submit(values as unknown as Address);
        },
    })

    const billingFormik = useFormik({
        initialValues: billingInitialValues,
        validationSchema,
        onSubmit: values => {
            submit(values as unknown as Address);
        },
    });

    const additionalFormik = useFormik({
        initialValues: additionalInitialValues,
        validationSchema,
        onSubmit: values => {
            submit(values as unknown as Address);
        },
    })

    useEffect(() => {
        getUserInfo();
        axios.get("countries").then((response) => {
            if (response) {
                setCountries(response?.data as Country[]);
            }
        })
    }, []);

    useEffect(() => {
        const mainAddress = userDetails?.address?.find(address => address.addressType === 'MAIN');
        const billingAddress = userDetails?.address?.find(address => address.addressType === 'BILLING');
        if (mainAddress) {
            setMainAddress(mainAddress);
            setShowMainAddressForm(false);
        } else {
            setMainAddress(undefined);
        }
        if (billingAddress) {
            setBillingAddress(billingAddress);
            setShowBillingAddressForm(false);
        } else {
            setBillingAddress(undefined);
        }
        setShowAdditionalAddressForm(false);
        const filteredAddresses = userDetails?.address?.filter(address =>
            address.addressType !== 'MAIN' && address.addressType !== 'BILLING'
        );
        setOtherAddresses(filteredAddresses || []);
    }, [userDetails]);

    const getUserInfo = () => {
        getUserDetails().then((response) => {
            if (response) {
                setUserDetails(response.data);
            } else {
                navigate("../", { replace: false });
            }
        });
    }

    const submit = (address: Address) => {
        if (canBeFormSubmitted(address)) {
            if (address.id == editingAddress || (isEditBilling && address.addressType == 'BILLING') || (isEditMain && address.addressType == 'MAIN')) {
                editAddress(address);
            } else {
                addAddress(address)
            }
        }
    }

    const addAddress = (address: Address) => {
        address.id = uuid();
        axios.post('UserDetails/Address', address).then((response) => {
            getUserInfo();
        }).catch(() => {
            toast.error(t("myAccountInfo.addressesBook.addAddressError"));
        });
    }

    const canBeFormSubmitted = (address: Address): boolean => {
        if (address.addressType === undefined || address.addressType.trim() === '') {
            address.addressType = "DELIVERY";
        }
        if (
            address.addressType === undefined || address.addressType.trim() === '' ||
            address.country === undefined || address.country.trim() === '' ||
            address.city === undefined || address.city.trim() === '' ||
            address.postalCode === undefined || address.postalCode.trim() === '' ||
            address.streetName === undefined || address.streetName.trim() === '' ||
            address.buildingNumber === undefined || address.buildingNumber.trim() === ''
        ) {
            toast.error(t("register.fillAllRequiredData"));
            return false;
        }
        return true;
    }

    const editAddress = (address: Address) => {
        axios.post('UserDetails/Address/Edit', address).then((response) => {
            getUserInfo();
            switch (address.addressType) {
                case "MAIN":
                    setIsEditMain(false);
                    break;
                case "BILLING":
                    setIsEditBilling(false);
                    break;
                default:
                    setEditingAddress(null);
            }
        }).catch(() => {
            toast.error(t("myAccountInfo.addressesBook.addAddressError"));
        });
    }

    const deleteAddress = (id: string) => {
        axios.get('UserDetails/Address/' + id).then((response) => {
            getUserInfo();
            resetBillingForm();
        }).catch(() => {
            toast.error(t("myAccountInfo.addressesBook.addAddressError"));
        });
    }

    const resetForm = () => {
        additionalFormik.values.addressType = '';
        additionalFormik.values.country = '';
        additionalFormik.values.postalCode = '';
        additionalFormik.values.city = '';
        additionalFormik.values.streetName = '';
        additionalFormik.values.buildingNumber = '';
        additionalFormik.values.flatNumber = '';
    }

    const resetBillingForm = () => {
        billingFormik.values.postalCode = '';
        billingFormik.values.city = '';
        billingFormik.values.streetName = '';
        billingFormik.values.buildingNumber = '';
        billingFormik.values.flatNumber = '';
    }

    const handleEditButton = (address: Address, type: string) => {
        switch (type) {
            case 'MAIN':
                setIsEditMain(true)
                break;
            case 'ADDITIONAL':
                setEditingAddress(address.id as string);
                setShowAdditionalAddressForm(false)
                break;
            case 'BILLING':
                setIsEditBilling(true);
                break;
        }
    }

    const showAdditionalAddressFormButtonAction = () => {
        setShowAdditionalAddressForm(true);
        resetForm();
    }

    return (
        <>
            <div className={styles.user_details_container}>
                <p className={styles.title}>{t("myAccountInfo.addressesBook.title")}</p>
                <div className={styles.user_data_container}>
                    <div className={styles.double_section}>
                        <div className={styles.section}>
                            <p className={styles.section_title}>{t("myAccountInfo.addressesBook.mainAddress")}</p>
                            {
                                mainAddress ? (
                                    isEditMain ? (
                                        <>
                                            {AddressForm(true, mainAddressFormik, mainAddress)}
                                        </>
                                    ) : (
                                        <>
                                            {AddressTile(mainAddress, undefined, 'MAIN')}
                                        </>
                                    )
                                ) : (
                                    <>
                                        {!showMainAddressForm ? (
                                            <div className={styles.no_orders_warning}>
                                                <div className={styles.warning_content}>
                                                    <WarningIcon className={styles.warning_icon} />
                                                    <p>{t("myAccountInfo.addressesBook.mainAddressWarning")}</p>
                                                </div>
                                                <button onClick={() => { setShowMainAddressForm(true) }} className={styles.go_shopping_button}>
                                                    {t("myAccountInfo.addressesBook.addMainAddress")}
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {AddressForm(true, mainAddressFormik)}
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </div>
                        <div className={styles.section}>
                            <p className={styles.section_title}>{t("myAccountInfo.addressesBook.billingAddress")}</p>
                            {
                                billingAddress ? (
                                    isEditBilling ? (
                                        <>
                                            {AddressForm(true, billingFormik, billingAddress)}
                                        </>
                                    ) : (
                                        <>
                                            {AddressTile(billingAddress, undefined, 'BILLING')}
                                        </>
                                    )
                                ) : (
                                    <>
                                        {!showBillingAddressForm ? (
                                            <div className={styles.no_orders_warning}>
                                                <div className={styles.warning_content}>
                                                    <WarningIcon className={styles.warning_icon} />
                                                    <p>{t("myAccountInfo.addressesBook.billingWarning")}</p>
                                                </div>
                                                <button onClick={() => setShowBillingAddressForm(true)} className={styles.go_shopping_button}>
                                                    {t("myAccountInfo.addressesBook.addBillingAddress")}
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                {AddressForm(true, billingFormik)}
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <p className={styles.section_title}>{t("myAccountInfo.addressesBook.additionalAddresses")}</p>
                    <div className={styles.double_section}>
                        {
                            otherAddresses?.length > 0 &&
                            <>
                                {
                                    otherAddresses?.map((address, index) => (
                                        <div key={index}>
                                            {editingAddress === address.id ? (
                                                AddressForm(false, additionalFormik, address)
                                            ) : (
                                                AddressTile(address)
                                            )}
                                        </div>
                                    ))
                                }
                            </>
                        }
                    </div>
                </div>
                {showAdditionalAddressForm ? AddressForm(false, additionalFormik) : <button onClick={showAdditionalAddressFormButtonAction} className={styles.add_additional_address_btn}>{t("myAccountInfo.addressesBook.addAdditionalAddress")}</button>}
            </div>
        </>
    )
}
