import { useEffect, useState, useRef } from 'react';
import styles from './RegulationsModal.module.scss';
import { getCmsByLanguageAndCode } from '../../../containers/cms-container/services';
import parse from 'html-react-parser';

export default function RegulationsModal(props: any) {
    const [cmsObject, setCmsObject] = useState<string>();
    const modalRef = useRef<HTMLDivElement>(null);

    const getCms = (code: string, languageCode: string): any => {
        return getCmsByLanguageAndCode(code, languageCode);
    }

    useEffect(() => {
        const savedLanguageCode = localStorage.getItem('lang');

        if (savedLanguageCode) {
            getCms(props.code, savedLanguageCode).then((response: any) => {
                if (response.data) {
                    setCmsObject(response.data);
                }
            });
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                props.close();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [localStorage.getItem('lang')]);

    const closeModal = () => {
        props.close();
    }

    return (
        <div className={`${styles.container} ${styles.open}`} ref={modalRef}>
            <div className={styles.modal}>
                <div className={styles.btn_container}>
                    <button className={styles.close_button} onClick={closeModal}>X</button>
                </div>
                {parse(cmsObject ? cmsObject : "")}
            </div>
        </div>
    )
}
