import { IPlugin, PluginStore } from "react-pluggable";
import { Store } from "redux";
import PickupPointPanel from "./components/PickupPointPanel/PickupPointPanel";
import axios from "axios";
import { setProductsWithPickupPoints } from "../store/actions";
import { ProductsIdsWithPickupPoints } from "../models/productsIdsWithPickupPoints.model";
class ShowPickupPointsPanelsPlugin implements IPlugin {

    pluginStore!: PluginStore;
    store!: Store;

    constructor(store: Store) {
        this.store = store
    }

    getPluginName(): string {
        return "ShowPickupPointsPanels@1.0.0";
    }

    getDependencies(): string[] {
        return [];
    }

    init(pluginStore: PluginStore): void {
        this.pluginStore = pluginStore;
    }

    activate(): void {
        this.pluginStore.executeFunction("Renderer.add", "page:product-details", () => (
            <PickupPointPanel></PickupPointPanel>
        ));
        this.pluginStore.addEventListener("getProductsExtendedInfo", (event: any) => {
            this.getProductsExtendedByPickupPoints();
        });
    }

    getProductsExtendedByPickupPoints() {
        axios.get<ProductsIdsWithPickupPoints>("products/pickup-points").then((response: any) => {
            if (response) {
                this.store.dispatch(setProductsWithPickupPoints(response?.data));
            }
        });
    }

    deactivate(): void {

    }
}

export default ShowPickupPointsPanelsPlugin;