import { useEffect, useState } from 'react';
import styles from './PickupMethods.module.scss';
import { useTranslation } from 'react-i18next';
import { getCmsByLanguageAndCode } from '../../containers/cms-container/services';
import parse from 'html-react-parser';

function getCms(code: string, languageCode: string): Promise<any> {
    return getCmsByLanguageAndCode(code, languageCode);
}

export default function PickupMethods() {
    const { t } = useTranslation('translation');
    const [images, setImages] = useState<string[]>([]);
    const [paragraphs, setParagraphs] = useState<string[]>([]);

    useEffect(() => {
        const lang = localStorage.getItem('lang');
        if (lang && lang !== '') {
            getCms('PICKUP_METHODS', lang).then((response: any) => {
                const htmlContent = response.data || '';
                const { extractedImages, extractedParagraphs } = extractHtmlTags(htmlContent);
                setImages(extractedImages);
                setParagraphs(extractedParagraphs);
            });
        }
    }, []);

    const extractHtmlTags = (html: string): { extractedImages: string[], extractedParagraphs: string[] } => {
        const imgTags: string[] = [];
        const pTags: string[] = [];
        const imgRegex = /<img[^>]*>/g;
        const pRegex = /<p[^>]*>.*?<\/p>/g;

        let match;

        while ((match = imgRegex.exec(html)) !== null) {
            imgTags.push(match[0]);
        }

        while ((match = pRegex.exec(html)) !== null) {
            pTags.push(match[0]);
        }

        return { extractedImages: imgTags, extractedParagraphs: pTags };
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.title}>
                {t("pickupMethods.title")}
            </div>
            <div className={styles.methods_wrapper}>
                {images.length > 0 && (
                    images.map((imgTag, index) => (
                        <div key={index} className={styles.item}>
                            {imgTag && imgTag !== '' && parse(imgTag)}
                            {paragraphs[index] && paragraphs[index] !== '' && parse(paragraphs[index])}
                        </div>
                    )))
                }
            </div>
        </div>
    );
}
