import axios from "axios";
import { SellingSessionPaginated } from "../models";
import { CartItem } from "../../cart/models";

export const getSellingSessionsPaginatedService = (pageNumber: number, pageSize: number, pickupPointId: string | null = null, deliveryMethodId: string | null = null): any => {
    return axios.get<SellingSessionPaginated>("SellingSession?pageNumber=" + pageNumber + "&pageSize=" + pageSize + "&pickupPointId=" + pickupPointId + "&deliveryMethodId=" + deliveryMethodId);
}

export async function getPickupPointWithSellingSessions(items: CartItem[]) {
    return axios.post("SellingSession/GetPickupPointWithSellingSessions", items);
}