import React, { useEffect, useState, useRef } from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import { getCmsByLanguageAndCode } from './services';
import styles from './CmsContainer.module.scss';
import { usePluginStore } from 'react-pluggable';

interface CmsContainerProps {
    code: string;
}

const decodeHtml = (html: string): string => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

function getCms(code: string, languageCode: string) {
    return getCmsByLanguageAndCode(code, languageCode);
}

const CmsContainer: React.FC<CmsContainerProps> = (props) => {
    const [cmsObject, setCmsObject] = useState<string>("");
    const pluginStore = usePluginStore();
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const savedLanguageCode = localStorage.getItem("lang");

        if (savedLanguageCode) {
            getCms(props.code, savedLanguageCode)
                .then((response: any) => {
                    const decodedHtml = decodeHtml(response.data);
                    setCmsObject(decodedHtml);
                })
                .catch((error: any) => {
                    console.error("Błąd pobierania CMS:", error);
                });
        }
    }, [props.code]);

    const Renderer = pluginStore.executeFunction("Renderer.getRendererComponent");

    return (
        <div>
            <Renderer placement={"page:cms"} />
            <div ref={containerRef} className={styles.main_container}>
                {cmsObject && parse(DOMPurify.sanitize(cmsObject))}
            </div>
        </div>
    );
};

export default CmsContainer;
