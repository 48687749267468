import { PickupPoint } from "../map/models"
import { MenuItem, Typography } from "@mui/material"
import styles from "./PickupPointList.module.scss"

export const PickupPointList = (props: any) => {
    return (
        <div className={styles.container}>
            {props.pickupPoints?.activePickupPoints && props.pickupPoints?.activePickupPoints?.map((pickupPoint: PickupPoint) => (
                <MenuItem key={pickupPoint?.id} value={pickupPoint?.id} className={styles.menuItem}>
                    <Typography variant="subtitle1" className={styles.title}>{pickupPoint?.name}</Typography>
                    <Typography variant="body2" color="textSecondary" className={styles.pickupPointAddress}>
                        {pickupPoint?.address?.streetName + " " + pickupPoint?.address?.buildingNumber}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className={styles.pickupPointAddress} >
                        {pickupPoint?.address?.postalCode + " " + pickupPoint?.address?.city}
                    </Typography>
                </MenuItem>
            ))}
        </div>
    )

}