import axios from "axios"
import { GetProducerAboutMeDto } from "../model/get-user-about-me.dto";
import { Producer } from "../../products-page/components/producers-list/models";

export const getProducerAboutMe = async (producerId: string) => {
    return axios.get<Producer>(`producers/${producerId}/AboutMe`);
}

export const getSchemaValueByObjectId = async (producerId: string) => {
    return axios.get<any>(`products/schemaValueByObjectId/${producerId}`);
}