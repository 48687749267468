import axios from "axios";
import Basket from "../models/basket.model";
import { CartItem } from "../../components/cart/models";
import BasketItem from "../models/basket-item.model";
import { getUserDetails } from "../../components/client-panel/components/my-account-info/services";

export const addBasketIfNeeded = async () => {
    if (checkIfNeedToAddBasket()) {
        const emptyBasket = await createEmptyBasket();
        return axios.post("Baskets/AddBasket", emptyBasket).then((response) => {
            if (response.data) {
                const addedBasket = response.data as Basket;
                emptyBasket.id = addedBasket.id;
                emptyBasket.code = addedBasket.code;
                emptyBasket.status = addedBasket.status;
                localStorage.setItem("basket", JSON.stringify(emptyBasket));
            }
        });
    }
}

export const updateBasket = async () => {
    const basketFromLocalStorage = localStorage.getItem("basket");
    const cartFromLocalStorage = localStorage.getItem("cart");

    if (cartFromLocalStorage !== null && basketFromLocalStorage) {
        try {
            const cart: CartItem[] = JSON.parse(cartFromLocalStorage);
            const basket: Basket = JSON.parse(basketFromLocalStorage);
            if (cart && basket) {
                basket.basketItems = basket.basketItems || [];

                cart.forEach((element: CartItem) => {
                    const existingBasketItem = basket.basketItems!.find(
                        (item) => item.productId === element.id
                    );

                    if (existingBasketItem) {
                        existingBasketItem.quantity = element.quantity;
                        existingBasketItem.itemBruttoPrice = element.totalPrice;
                    } else {
                        const basketItem: BasketItem = {
                            productId: element.id,
                            name: element.name,
                            quantity: element.quantity,
                            itemBruttoPrice: element.totalPrice
                        };

                        basket.basketItems!.push(basketItem);
                    }
                });

                basket.basketItems = basket.basketItems.filter((basketItem) => {
                    return cart.some(cartItem => cartItem.id === basketItem.productId);
                });

                const totalPrice = basket.basketItems.reduce((total, item) => {
                    return total + item.itemBruttoPrice;
                }, 0);
                basket.totalPrice = totalPrice;

                localStorage.setItem("basket", JSON.stringify(basket));
                axios.post("Baskets/UpdateBasket", basket as Basket);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    } else {
    }
};

export const setBasketAsOrdered = (clientName?: string, clientSurname?: string) => {
    const basketFromLocalStorage = localStorage.getItem("basket");
    if (basketFromLocalStorage) {
        const basket: Basket = JSON.parse(basketFromLocalStorage);
        if (clientName && clientSurname) {
            basket.clientName = clientName;
            basket.clientSurname = clientSurname;
        }
        basket.status = "ORDERED";
        axios.post("Baskets/UpdateBasket", basket as Basket).then(() => {
            localStorage.removeItem("basket");
        });
        addBasketIfNeeded();
    }
}


export const checkIfNeedToAddBasket = (): boolean => {
    return localStorage.getItem("basket") === null;
};

const fetchIp = async () => {
    const response = await axios.get<{ ip: string }>('Baskets/get-ip');
    if (response.data) {
        return response.data.ip;
    }
    else {
        return ''
    }
};

export const createEmptyBasket = async (): Promise<Basket> => {
    const clientIp = await fetchIp();
    const clientBrowser = navigator.userAgent || 'Unknown Browser';
    const basket: Basket = {
        code: '',
        clientIp: clientIp,
        clientBrowser: clientBrowser,
        basketItems: [],
        totalPrice: 0,
        currencyName: 'PLN',
    }
    if (localStorage.getItem("token") !== null) {
        await getUserDetails().then((response) => {
            if (response.data) {
                basket.clientName = response.data.user?.firstName;
                basket.clientSurname = response.data.user?.lastName;
            }
        });
    }
    return basket;
};
