import { t } from "i18next";
import { formatDateTimeService } from "../../../../shared/services";
import { SellingSession } from "../../../selling-sessions/models";
import styles from './SellingSessionsTable.module.scss';

interface SellingSessionsTableProps {
    title: string;
    sellingSessions: SellingSession[];
}

export default function SellingSessionsTable({ title, sellingSessions }: SellingSessionsTableProps) {

    return (
        <div>
            <h4>{title}:</h4>
            <table className={styles.selling_sessions_table}>
                <thead>
                    <tr>
                        <th>{t("sellingSessions.table.startOrderingDate")}</th>
                        <th>{t("sellingSessions.table.endOrderingDate")}</th>
                        <th>{t("sellingSessions.table.startPickupDate")}</th>
                        <th>{t("sellingSessions.table.endPickupDate")}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellingSessions?.map((ss: SellingSession, index: number) => (
                            <tr key={index}>
                                <td className={styles.centered_td}>{formatDateTimeService(ss.startOrderingDate)}</td>
                                <td className={styles.centered_td}>{formatDateTimeService(ss.endOrderingDate)}</td>
                                <td className={styles.centered_td}>{formatDateTimeService(ss.startPickupDate)}</td>
                                <td className={styles.centered_td}>{formatDateTimeService(ss.endPickupDate)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )

}