import React, { useState } from "react";
import styles from "./DropdownDisplay.module.scss";
import { PickupPoint } from "../map/models";

interface DropdownDisplayProps {
    pickupPoints?: PickupPoint[];
}

export default function DropdownDisplay({ pickupPoints = [] }: DropdownDisplayProps) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    return (
        <div className={styles.dropdownDisplay}>
            <div className={styles.dropdownHeader} onClick={toggleDropdown}>
                <span>{pickupPoints.length > 0 && pickupPoints[0].name !== null && pickupPoints[0]?.name || "Brak punktów odbioru"}</span>
                <span className={styles.dropdownArrow}>{isOpen ? "▲" : "▼"}</span>
            </div>
            {isOpen && pickupPoints.length > 0 && (
                <div className={styles.dropdownOptions}>
                    {pickupPoints.map((pickupPoint, index) => (
                        <div key={index} className={styles.dropdownOption} onClick={toggleDropdown}>
                            <span className={styles.optionLabel}>{pickupPoint.name}</span>
                            {pickupPoint.address && (
                                <>
                                    <span className={styles.optionAddress}>
                                        {pickupPoint.address?.streetName} {pickupPoint.address?.buildingNumber}
                                    </span>
                                    <span className={styles.optionAddress}>
                                        {pickupPoint.address?.postalCode} {pickupPoint.address?.city}
                                    </span>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
