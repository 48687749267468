import React, { useEffect, useRef, useState } from 'react';
import styles from './NewestProducts.module.scss';
import { getNewestProducts } from './services';
import { Product } from '../../models';
import ButtonArrowBack from '../../../../shared/ui-elements/button-with-arrow-back/ButtonArrowBack';
import ButtonArrowForward from '../../../../shared/ui-elements/button-with-arrow-forward/ButtonArrowForward';
import { useTranslation } from 'react-i18next';
import ProductCard from '../../../../shared/product-card/ProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import './swiper-styles.scss';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { Filters } from '../../../../models/filters.model';

export default function NewestProducts(props: any) {
    const filters = useSelector((state: RootState) => state.filters);

    const [newestProducts, setNewestProducts] = useState<Product[]>([]);
    const [beginning, setBeginning] = useState<boolean>(true);
    const [end, setEnd] = useState<boolean>(false);
    const swiperRef = useRef<any>(null);
    const selectedPickupPoint = localStorage.getItem('pickupPointsFilter');
    const [t, i18] = useTranslation();

    const getProducts = (productFilters: Filters): void => {
        setNewestProducts([]);
        getNewestProducts(productFilters).then((response) => {
            const products = response?.data as Product[];
            setNewestProducts(products);
            const width = window.innerWidth;
            let slidesPerView = 1;
            if (width >= 1680) slidesPerView = Math.min(4, products?.length);
            else if (width >= 1024) slidesPerView = Math.min(3, products?.length);
            else if (width >= 768) slidesPerView = Math.min(2, products?.length);

            if (products?.length <= slidesPerView) {
                setEnd(true);
            }
        });
    };
    useEffect(() => {
        const selectedPickupPoint = localStorage.getItem('pickupPointsFilter');
        if ((filters !== undefined && filters.pickupPointId !== null) || selectedPickupPoint === null || selectedPickupPoint === '' && filters.productName !== null) {
            getProducts(filters);
        }
    }, [filters]);

    const handleCartItemsChange = (): void => {
        props.onCartItemsChange();
    };

    return (
        <div className={styles.main_container}>
            <div className={styles.upper_part}>
                <div className={styles.left}>
                    {t('newestProducts.new')}
                    <div className={styles.green_horizontal_line}></div>
                </div>
                <div className={styles.right}>
                    <ButtonArrowBack handleClick={() => swiperRef?.current?.swiper?.slidePrev()} isClickable={!beginning} />
                    <ButtonArrowForward handleClick={() => swiperRef?.current?.swiper?.slideNext()} isClickable={!end} />
                </div>
            </div>
            <div className={styles.items}>
                <div className={styles.swiper_container}>
                    {
                        <Swiper
                            ref={swiperRef}
                            key={newestProducts?.length}
                            slidesPerView={1} //Default value, then it will change on breakpoints
                            modules={[Navigation]}
                            className={styles.mySwiper}
                            onSlideChange={() => {
                                setBeginning(swiperRef.current?.swiper?.isBeginning);
                                setEnd(swiperRef.current?.swiper?.isEnd);
                            }}
                            spaceBetween={15}
                            focusableElements='div, ul, li, input, select, option, textarea, button, video, label'
                            breakpoints={{
                                640: {
                                    slidesPerView: Math.min(newestProducts?.length, 1)
                                },
                                1024: {
                                    slidesPerView: Math.min(newestProducts?.length, 2)
                                },
                                1300: {
                                    slidesPerView: Math.min(newestProducts?.length, 3)
                                }
                            }}
                        >
                            {newestProducts &&
                                newestProducts.length > 0 &&
                                newestProducts?.map((product: Product, index: number) => (
                                    <SwiperSlide key={index} className={styles.swiper_slide}>
                                        <ProductCard key={index} product={product} onCartItemsChange={handleCartItemsChange} />
                                    </SwiperSlide>
                                ))}
                        </Swiper>
                    }
                </div>
            </div>
        </div>
    );
}
