import { combineReducers } from 'redux';
import { SET_FILTERS, FiltersAction, SET_PRODUCTS_WITH_PICKUP_POINTS, ProductsIdsWithPickupPointsAction, CurrencyAction, SET_CURRENCY } from './actions';
import { Filters } from '../models/filters.model';
import { ProductsIdsWithPickupPoints } from '../models/productsIdsWithPickupPoints.model';

// Currency reducer
export interface CurrencyState {
    name: string;
}

const currencyInitialState: CurrencyState = {
    name: ""
};

const currencyReducer = (state = currencyInitialState, action: CurrencyAction): CurrencyState | string => {
    switch (action.type) {
        case SET_CURRENCY:
            return action.payload;
        default:
            return state;
    }
};

// Filters reducer
export interface FilterState {
    filters: Filters;
}

const filtersInitialState: Filters = {
    pickupPointId: null,
    productName: null,
    categoryId: null
};

const filtersReducer = (state = filtersInitialState, action: FiltersAction): Filters => {
    switch (action.type) {
        case SET_FILTERS:
            return action.payload;
        default:
            return state;
    }
};

// ProductsIdsWithPickupPoint reducer
export interface ProductsState {
    productsIdsWithPickupPoints: ProductsIdsWithPickupPoints[];
}

const productsIdsWithPickupPointsInitialState: ProductsIdsWithPickupPoints[] = [];

const productsIdsWithPickupPointsReducer = (
    state = productsIdsWithPickupPointsInitialState,
    action: ProductsIdsWithPickupPointsAction
): ProductsIdsWithPickupPoints[] | undefined => {
    switch (action.type) {
        case SET_PRODUCTS_WITH_PICKUP_POINTS:
            return action.payload;
        default:
            return state;
    }
};

// Combined reducer
export default combineReducers({
    currency: currencyReducer,
    filters: filtersReducer,
    productsIdsWithPickupPoints: productsIdsWithPickupPointsReducer,
});
