import axios from "axios";

export const getProducerAttachments = (producerId: string) => {
    return axios.get(`images/attachments/producer/${producerId}`);
}

export const getProductAttachments = (productId: string) => {
    return axios.get(`images/attachments/product/${productId}`);
}

export const getImageById = (imageId: string) => {
    return axios.get(`images/category/${imageId}`)
}

const fetchImage = async (endpoint: string, fileId: string, width: number, height: number): Promise<string | null> => {
    const response = await axios.get(`images/${endpoint}/${fileId}/${width}/${height}`, {
        responseType: 'blob',
    });

    if (response.status === 200 && response.data.size > 0) {
        return URL.createObjectURL(response.data);
    } else {
        return null;
    }
};

export const getDimensionedImage = (fileId: string, width: number, height: number): Promise<string | null> => {
    return fetchImage('GetDimensionedImage', fileId, width, height);
};

export const getDimensionedProductImage = (fileId: string, width: number, height: number): Promise<string | null> => {
    return fetchImage('GetDimensionedProductImage', fileId, width, height);
}